import { useNavigate } from "react-router-dom";
import { PDFList, Button } from "../../components";

const Home = () => {
  const navigate = useNavigate();
  const handleUploadRedirect = () => {
    navigate("/upload");
  };
  return (
    <div className="mx-3">
      <h1 className="my-3 text-center font-bold text-3xl">Interactive PDF</h1>
      <PDFList />
      <div className="w-full my-5 mx-auto text-center">
        <Button onClick={handleUploadRedirect}>Upload New File</Button>
      </div>
    </div>
  );
};

export { Home };
