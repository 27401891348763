import React, { useState } from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Button } from "../Button/Button";
import { Input } from "../Input/Input";
import { Dropdown } from "../Dropdown/Dropdown";
import { Checkbox } from "../Checkbox/Checkbox";
import axios from "axios";
import { processInteractivePdf } from "../../utils";
import { useSelector } from "react-redux";

const InteractiveReadingOverlay = ({
  toggleOverlay,
  resourceUrl,
  resourceId,
  toggleShowProcessingOverlay,
}) => {
  const [outputFileName, setOutputFileName] = useState("test-output-filename");
  const [wordsToInclude, setWordsToInclude] = useState("");
  const [wordsToExclude, setWordsToExclude] = useState("");
  const [targetAudience, setTargetAudience] = useState("beginner");

  const [includeFAQ, setIncludeFAQ] = useState(false);
  const [includeTerminologies, setIncludeTerminologies] = useState(false);
  const [includeQuiz, setIncludeQuiz] = useState(false);

  const availableTagretAudience = ["beginner", "intermediate", "expert"];

  const updateFileName = (e) => {
    setOutputFileName(e.target.value);
  };

  const playgroundState = useSelector((state) => state?.playground);
  const { domain } = playgroundState;

  const notesState = useSelector((state) => state?.notes);
  const { sectionsList } = notesState;

  const highlightsState = useSelector((state) => state?.highlights);
  const { annotationList } = highlightsState;

  const handleProcessPdf = async () => {
    toggleOverlay();
    toggleShowProcessingOverlay();
    const res = await processInteractivePdf({
      outputFileName: outputFileName,
      audiencePersona: targetAudience,
      includeFaqs: includeFAQ,
      includeTerminologies: includeTerminologies,
      includeQuiz: includeQuiz,
      pdfUrl: resourceUrl,
      resourceId: resourceId,
      domain: domain,
      sections: sectionsList,
      highlights: annotationList.map((ele) => {
        return {
          annotation: ele.annotation,
          text: ele.text,
          originalText: ele.originalText,
        };
      }),
    });
  };
  return (
    <div className="overlay flex justify-center items-center ">
      <div className="flex flex-col justify-center items-center gap-3 bg-[#f0f0f0] p-5 rounded relative">
        <div
          className="absolute top-2 right-2 cursor-pointer"
          onClick={toggleOverlay}
        >
          <IoMdCloseCircleOutline />
        </div>
        <h3 className="text-center font-bold text-2xl mb-3 mt-2">
          Configure Export
        </h3>
        <div className="flex flex-col gap-4 my-2">
          <div className="flex gap-5 items-center">
            <p className="mb-1">Output FileName </p>
            <Input value={outputFileName} onChange={updateFileName} />
          </div>

          <div className="flex gap-4 items-center">
            <p className="mb-1">Audience Persona</p>
            {/* <Input value={outputFileName} updateValue={setWordsToInclude} /> */}
            <Dropdown
              defaultValue={"beginner"}
              options={availableTagretAudience}
              value={targetAudience}
              setValue={setTargetAudience}
            />
          </div>

          {/* <div className="flex gap-5 items-center">
            <p className="mb-1">Words To Include </p>
            <Input
              value={wordsToInclude}
              updateValue={setWordsToInclude}
              placeHolder={"Comma seperated values"}
            />
          </div> */}

          {/* <div className="flex gap-5 items-center">
            <p className="mb-1">Words To Include </p>
            <Input
              value={wordsToExclude}
              updateValue={setWordsToExclude}
              placeHolder={"Comma seperated values"}
            />
          </div> */}
        </div>
        <div className="flex gap-2">
          <Checkbox isChosen={includeFAQ} setIsChosen={setIncludeFAQ}>
            Include FAQs
          </Checkbox>
          <Checkbox
            isChosen={includeTerminologies}
            setIsChosen={setIncludeTerminologies}
          >
            Include Terminologies
          </Checkbox>
          <Checkbox isChosen={includeQuiz} setIsChosen={setIncludeQuiz}>
            Include Quiz
          </Checkbox>
        </div>
        <Button onClick={handleProcessPdf}>Process</Button>
      </div>
    </div>
  );
};

export { InteractiveReadingOverlay };
