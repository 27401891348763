import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useParams } from "react-router-dom";
import { getOutputDetails } from "../../utils";
import toast from "react-hot-toast";

const DeckOutputPage = () => {
  const [outputData, setOutputData] = useState({});
  const { outputId } = useParams();
  useEffect(() => {
    (async () => {
      try {
        const res = await getOutputDetails(outputId);
        setOutputData(res);
      } catch (e) {
        setOutputData({});
        toast.error("Something went wrong");
      }
    })();
  }, [outputId]);

  return (
    <div className="flex flex-col gap-10 items-center mt-5">
      <p className="font-bold text-4xl">{outputData?.title}</p>
      <DocViewer
        documents={[
          {
            uri: outputData?.url,
            fileType: "pptx",
          },
        ]}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            disableHeader: true,
          },
        }}
        style={{ height: "65vh", width: "60%" }}
      />
    </div>
  );
};

export { DeckOutputPage };
