import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  history: [],
  selectedContext: "",
  page: -1,
  bbox: [],
  showLoader: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addToHistory: (state, action) => {
      const newMessage = action?.payload;
      state.history = [...state?.history, newMessage];
    },
    resetHistory: (state, action) => {
      state.history = [];
    },
    setSelectedContext: (state, action) => {
      const { text, page, bbox } = action?.payload;
      state.selectedContext = text;
      state.page = page;
      state.bbox = bbox;
    },
    resetSelectedContext: (state, action) => {
      state.selectedContext = "";
      state.page = -1;
      state.bbox = [];
    },
    toggleShowLoader: (state, action) => {
      state.showLoader = !state.showLoader;
    },
  },
});

export const {
  addToHistory,
  setSelectedContext,
  resetSelectedContext,
  toggleShowLoader,
  resetHistory,
} = chatSlice.actions;

export default chatSlice.reducer;
