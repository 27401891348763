import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import {
  FileUploadModal,
  HighlightInputModal,
  InteractiveReadingOverlay,
  NotesPanel,
  PDFRenderer,
  ProcessingOverlay,
} from "../../components";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getPdfData } from "../../utils";
import { useDispatch } from "react-redux";
import { setDomain } from "../../features";
import { VideoGenOverlay } from "../../components/VideoGenOverlay/VideoGenOverlay";
import { useSelector } from "react-redux";

const AnnotationPage = () => {
  const canvasRef = useRef(null);
  const pdfRendererRef = useRef(null);
  const fabricCanvasRef = useRef(null);

  const { resourceId } = useParams();
  const [resourceUrl, setResourceUrl] = useState("");
  const {
    highlights: { showHighlightModal },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [showIntreactiveReadingOverlay, setShowInteractiveReadingOverlay] =
    useState(false);

  const toggleInteractiveReadingOverlay = () => {
    setShowInteractiveReadingOverlay((prev) => !prev);
  };

  const [showVideoGenOverlay, setShowVideoGenOverlay] = useState(false);

  const toggleShowVideoGenOverlay = () => {
    setShowVideoGenOverlay((prev) => !prev);
  };

  const [showProcessingOverlay, setShowProcessingOverlay] = useState(false);

  const toggleShowProcessingOverlay = () => {
    setShowProcessingOverlay((prev) => !prev);
  };

  function addImageToMiddle(imageUrl) {
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas) {
      fabric.Image.fromURL(
        // "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
        imageUrl,
        function (img) {
          img.scale(2).set({
            // id: "image_" + imageUrl,
            left: fabricCanvas.width / 2 - 100,
            top: fabricCanvas.height / 2 - 100,
            width: 100,
            height: 100,
            // controls: {
            //   ...fabric.Rect.prototype.controls,
            //   mtr: new fabric.Control({ visible: false }),
            // },
          });

          fabricCanvas.add(img);
          fabricCanvas.setActiveObject(img);
          fabricCanvas.bringToFront(img);
          fabricCanvas.renderAll();
        },
        { crossOrigin: "Anonymous" }
      );
    }
  }

  useState(() => {
    (async function () {
      try {
        const response = await getPdfData(resourceId);
        const { domain: newDomain, url: newUrl } = response;
        setResourceUrl(newUrl);
        dispatch(setDomain(newDomain));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [resourceId]);
  return (
    <div className="flex flex-col items-center justify-center mx-6 h-screen relative">
      {showIntreactiveReadingOverlay && (
        <InteractiveReadingOverlay
          toggleOverlay={toggleInteractiveReadingOverlay}
          resourceUrl={resourceUrl}
          resourceId={resourceId}
          toggleShowProcessingOverlay={toggleShowProcessingOverlay}
          pdfRendererRef={pdfRendererRef}
        />
      )}

      {showVideoGenOverlay && (
        <VideoGenOverlay
          toggleOverlay={toggleShowVideoGenOverlay}
          resourceUrl={resourceUrl}
          resourceId={resourceId}
          toggleShowProcessingOverlay={toggleShowProcessingOverlay}
          pdfRendererRef={pdfRendererRef}
        />
      )}
      {showProcessingOverlay && (
        <ProcessingOverlay toggleOverlay={toggleShowProcessingOverlay} />
      )}

      {showHighlightModal && <HighlightInputModal />}
      {resourceUrl && (
        <div className="flex flex-row w-full grow mb-2 overflow-y-hidden no-scrollbar">
          <FileUploadModal addImageToMiddle={addImageToMiddle} />
          <PDFRenderer
            canvasRef={canvasRef}
            pdfRendererRef={pdfRendererRef}
            resourceUrl={resourceUrl}
            fabricCanvasRef={fabricCanvasRef}
          />
          <div
            className="message-pane flex flex-col justify-between w-4/12 bg-black/0 rounded-
         border-1 border-dashed border-slate-800/60 overflow-y-scroll"
          >
            <NotesPanel
              pdfRendererRef={pdfRendererRef}
              toggleInteractiveReadingOverlay={toggleInteractiveReadingOverlay}
              toggleShowVideoGenOverlay={toggleShowVideoGenOverlay}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { AnnotationPage };
