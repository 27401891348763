import React, { useRef, useState } from "react";
import BlockTextArea from "../BlockTextArea/BlockTextArea";
import { useDispatch } from "react-redux";
import {
  deleteBlock,
  resetEditingBlock,
  setEditingBlock,
  updateBlockNote,
} from "../../features";
import { IoTriangleSharp } from "react-icons/io5";
import { MarkdownRenderer } from "../MarkdownRenderer/MarkdownRenderer";
import { MdDelete, MdDragIndicator } from "react-icons/md";
import { useDetectClick } from "../../hooks";
import { useSelector } from "react-redux";
import { FiEye } from "react-icons/fi";

const IntroOutroBlock = ({
  id,
  selectedText,
  content,
  sectionId,
  dragProps,
  type,
  placeHolder,
}) => {
  const [showSelectedText, setShowSelectedText] = useState(false);
  const userInputRef = useRef(null);
  const textAreaRef = useRef(null);
  const dispatch = useDispatch();
  const updateBlockContent = (newValue) => {
    dispatch(
      updateBlockNote({ sectionId, blockId: id, updatedNote: newValue })
    );
  };

  const handleDeleteBlock = () => {
    dispatch(deleteBlock({ sectionId, blockId: id }));
  };
  const handleDisplaySelectedText = () => {
    setShowSelectedText((prev) => !prev);
  };
  const editingBlockId = useSelector((state) => state?.notes?.editingBlockId);
  const isEditing = id === editingBlockId;

  const handleClick = () => {
    if (editingBlockId !== id) {
      dispatch(setEditingBlock({ blockId: id }));
    }
  };

  useDetectClick(userInputRef, () => {
    if (isEditing) {
      dispatch(resetEditingBlock({ blockId: id }));
    }
  });
  const textAreaClasses =
    "mx-0 px-5 py-3 min-h-[35vh] rounded-none leading-[28px] ";
  return (
    <div key={id}>
      {dragProps && (
        <div {...dragProps} className="ml-2">
          <MdDragIndicator />
        </div>
      )}
      <div
        onClick={handleClick}
        ref={userInputRef}
        className="relative"
        // className={"relative " + (isEditing ? "" : "bg-[#74c5ff]")}
      >
        <div
          className="absolute cursor-pointer top-2 right-3 z-10 "
          onClick={handleDeleteBlock}
        >
          <MdDelete />
        </div>
        {isEditing ? (
          <div className="mx-0 my-2 ">
            <BlockTextArea
              value={content}
              updateValue={updateBlockContent}
              classes={textAreaClasses}
              style={{ borderLeft: "8px solid #74c5ff" }}
              // parentClasses={"border-l-8 border-red-500"}
              showBoxShadow={true}
              autoFocusOnRender={true}
              minHeight={" min-h-[5vh] "}
              eleref={textAreaRef}
            />
          </div>
        ) : (
          <div className=" rounded-lg my-2 relative mx-0 mr-0 ">
            <MarkdownRenderer
              content={content}
              classes={
                " shadow-lg px-0 py-0 cursor-text rounded-none border-l-8 border-[#74c5ff] "
              }
              placeHolder={placeHolder}
              minHeight={" min-h-[5vh] "}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { IntroOutroBlock };
