import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { dateToString } from "../../utils";
import { Link } from "react-router-dom";

const VideoInputsListPage = () => {
  const [videoOuputs, setVideoOutputs] = useState([]);
  //   useEffect(() => {
  //     try {
  //       const res = axios.get(
  //         `${process.env.REACT_APP_BACKEND_BASE_URL}/get_video_inputs`
  //       );
  //       console.log({ res });
  //     } catch (e) {
  //       toast.error("Something went wrong");
  //     }
  //   }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/get_video_inputs`
        );
        setVideoOutputs(res?.data);
      } catch (e) {
        toast.error("Something went wrong");
      }
    })();
  }, []);
  return (
    <div className="overflow-x-auto">
      <table className="table-auto min-w-full divide-y divide-gray-200">
        <thead>
          <tr className="bg-gray-50 text-left">
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Title
            </th>
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Domain
            </th>
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Date and Time
            </th>
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Checkout
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {videoOuputs.map((ele) => {
            return (
              <tr key={ele._id} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {ele.outputFileName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {ele?.domain}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {dateToString(ele?.created_at)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <Link
                    to={`/video-inputs/${ele?._id}`}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    Checkout
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export { VideoInputsListPage };
