import React, { useState } from "react";
import { GoPencil } from "react-icons/go";
import { LuImagePlus } from "react-icons/lu";

import { FaRegCircle } from "react-icons/fa";
import { toggleFileUploadModal } from "../../features";
import { useDispatch } from "react-redux";

const Topbar = ({}) => {
  const dispatch = useDispatch();
  const tools = [
    {
      name: "Image",
    },
    // {
    //   name: "Circle",
    // },
    {
      name: "WhiteBoard",
    },
  ];

  const getComponent = (type) => {
    switch (type) {
      case "Image":
        return <LuImagePlus />;
      case "WhiteBoard":
        return <GoPencil />;
      default:
        return null;
    }
  };

  const handleShapeClick = (tool) => {
    switch (tool) {
      case "Image":
        dispatch(toggleFileUploadModal());
        break;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col w-[100%] items-center z-[1000] absolute top-0">
      <div className="flex bg-[#f1eded] rounded-bl-lg rounded-br-lg">
        {tools.map((tool) => {
          const { name } = tool;
          return (
            <div
              className={
                " p-2 m-3 cursor-pointer rounded-lg border border-slate-500 bg-[#fffde8] "
              }
              onClick={() => handleShapeClick(name)}
              key={name}
            >
              {getComponent(name)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { Topbar };
