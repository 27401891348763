import React, { useEffect, useRef, useState } from "react";
// import ReactMarkdown from "react-markdown";

const TextArea = ({
  value,
  onChange,
  classes,
  placeHolder,
  minHeight,
  autoFocusOnRender = true,
  hasMinHeight = true,
}) => {
  const isValueEmpty = !value?.trim();

  useEffect(() => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height =
      textareaRef.current.scrollHeight + 6 + "px";
  }, [value]);

  useEffect(() => {
    if (autoFocusOnRender) {
      // move cursor to end, making cursor move to the end
      textareaRef.current.selectionStart = textareaRef.current.value.length;
      textareaRef.current.selectionEnd = textareaRef.current.value.length;

      textareaRef.current.focus();
    }
  }, [autoFocusOnRender]);

  const textareaRef = useRef(null);

  return (
    <div
      className={
        "mb-3 bg-white shadow-md rounded-lg " +
        (classes ? classes : "") +
        (minHeight ? minHeight : "")
      }
    >
      <textarea
        className={
          "no-scrollbar w-[100%] resize-none p-3 outline-none " +
          (hasMinHeight ? "min-h-[150px] " : "") +
          (isValueEmpty ? " text-[#777]" : "")
        }
        onChange={onChange}
        ref={textareaRef}
        value={value}
      >
        {isValueEmpty ? placeHolder : value}
      </textarea>
    </div>
  );
};

export { TextArea };
