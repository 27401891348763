import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNote,
  deleteHighlightAnnotation,
  rearrangeNotes,
  removeAll,
  resetsectionsList,
  updateCurrentNote,
  updateHighlightAnnotation,
} from "../../features";
import { NoteCard } from "../NoteCard/NoteCard";
import { Button } from "../Button/Button";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import toast from "react-hot-toast";
import { Section } from "../Section/Section";
import {
  generateVideo,
  processSectionsList,
  unsecuredCopyToClipboard,
} from "../../utils";
import { createEmptySection } from "../../features/notes/notesSlice";
import { MdContentCopy, MdDelete } from "react-icons/md";
import BlockTextArea from "../BlockTextArea/BlockTextArea";
import { TextArea } from "../TextArea/TextArea";

const NotesPanel = ({
  pdfRendererRef,
  toggleInteractiveReadingOverlay,
  toggleShowVideoGenOverlay,
}) => {
  const dispatch = useDispatch();
  const notesState = useSelector((state) => state.notes);
  const { annotationList } = useSelector((state) => state.highlights);
  const { sectionsList, current } = notesState;

  const addNonContextualCard = () => {
    dispatch(
      updateCurrentNote({
        selectedText: "",
        isNonContextual: true,
      })
    );
  };

  const handleCreateNewSection = () => {
    dispatch(createEmptySection());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/get_template`
        );
        const newsectionsList = response?.data;
        dispatch(resetsectionsList({ newsectionsList }));
      } catch (error) {
        toast.error("Something went wrong");
      }
    };
    fetchData();
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(sectionsList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(rearrangeNotes({ newOrder: items }));
    //  setTasks(items);
  };

  const copyNormalizedSection = () => {
    const { clientWidth: width, clientHeight: height } = pdfRendererRef.current;
    const res = processSectionsList(sectionsList, { height, width });
    // navigator.clipboard.writeText(JSON.stringify(res));
    unsecuredCopyToClipboard(JSON.stringify(res));
    toast.success("Sections copied to clipboard!");
  };

  const handleCreateVideo = async () => {
    // toast.success("WIP");
    // return;
    // const { clientWidth: width, clientHeight: height } = pdfRendererRef.current;
    // let result = await generateVideo(sectionsList, { height, width });
    toggleShowVideoGenOverlay();
  };

  const handleCreateInteractiveReading = async () => {
    toggleInteractiveReadingOverlay();
  };

  const handleCreateSlide = async () => {
    toast.success("WIP");
    return;
  };

  const handleDeleteHighlight = (id) => {
    dispatch(deleteHighlightAnnotation({ id }));
  };

  return (
    <div className="mx-3">
      <div className="flex justify-center items-center bg-white sticky top-0 z-50 px-2 py-3 border-b-2">
        <div className="flex justify-center items-center gap-3">
          <h2 className="text-3xl text-center font-bold">Sections</h2>
          <MdContentCopy
            className="cursor-pointer"
            onClick={copyNormalizedSection}
          />
        </div>
        <div className="ml-auto">
          <Button onClick={handleCreateNewSection}>Add New Section</Button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {/* ----------------------------------------------------------------------- */}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="notes">
            {(provided) => (
              <div
                className="flex flex-col gap-4 my-4"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {sectionsList?.map((ele, index) => {
                  // const { selectedText, editing, note, id } = ele;
                  const { id, title, context, blocks, guidelines } = ele;
                  const length = sectionsList.length;
                  return (
                    //id has to be string for react dnd
                    <Draggable
                      key={`${id}`}
                      draggableId={`${id}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Section
                            id={id}
                            title={title}
                            context={context}
                            blocks={blocks}
                            guidelines={guidelines}
                            dragProps={{ ...provided.dragHandleProps }}
                            length={length}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {/* ----------------------------------------------------------------------- */}

        {/* {sectionsList?.map((ele, index) => {
          // const { selectedText, editing, note, id } = ele;
          const { id, title, context, blocks, guidelines } = ele;
          return (
            <div>
              <Section
                id={id}
                title={title}
                context={context}
                blocks={blocks}
                guidelines={guidelines}
              />
            </div>
          );
        })} */}

        {/* <div className="flex gap-2 justify-center py-5 sticky bottom-0 bg-white">
          <Button onClick={handleCreateInteractiveReading}>
            Export for interactive reading
          </Button>
          <Button onClick={handleCreateVideo}>Export as Video</Button>
          <Button onClick={handleCreateSlide}>Export as Presentation</Button>
        </div> */}
        {annotationList?.length > 0 && (
          <div>
            <p className="text-lg font-bold">Highlights</p>
            {annotationList.map((ele) => {
              return (
                <div className="bg-[#f1eded] p-4 rounded-md relative mb-2">
                  <div
                    className="mb-2 flex justify-end absolute top-2 right-3 cursor-pointer"
                    onClick={() => handleDeleteHighlight(ele?.id)}
                  >
                    <MdDelete />
                  </div>
                  <p className="bg-slate-50 p-3 max-h-[200px] overflow-y-auto no-scrollbar my-3 rounded-md">
                    {ele?.text}
                  </p>
                  {/* <p className="bg-slate-50 p-3 max-h-[200px] overflow-y-auto no-scrollbar">
                    {ele?.annotation}
                  </p> */}
                  <TextArea
                    value={ele?.annotation}
                    autoFocusOnRender={false}
                    hasMinHeight={false}
                    onChange={(e) =>
                      dispatch(
                        updateHighlightAnnotation({
                          id: ele.id,
                          newAnnotationText: e.target.value,
                        })
                      )
                    }
                  />
                </div>
              );
            })}
          </div>
        )}
        <div className="flex gap-2 justify-center py-5 sticky bottom-0 bg-white">
          <Button onClick={toggleInteractiveReadingOverlay}>
            Export For Interactive Reading
          </Button>
        </div>
      </div>
    </div>
  );
};

export { NotesPanel };
