import React, { useEffect, useRef, useState } from "react";
import { PluginsDropdown } from "../PluginsDropdown/PluginsDropdown";

const BlockTextArea = ({
  value,
  updateValue,
  classes,
  isDisabled,
  placeHolder,
  style,
  showBoxShadow,
  autoFocusOnRender,
  minHeight,
  parentClasses,
}) => {
  const [contextMenu, setContextMenu] = useState({
    show: false,
    position: { x: 0, y: 0 },
    selectedText: "",
  });
  const pluginsDropdownRef = useRef(null);
  const handleChange = (e) => {
    updateValue(e?.target?.value);
  };
  const handleContextMenu = (e) => {
    e.preventDefault();

    //  TODO: Show context

    const selectedText = window.getSelection().toString();
    const notesPanelRect = e.currentTarget.getBoundingClientRect();

    // Calculate the position relative to the NotesPanel
    const positionXRelativeToPanel = e.clientX - notesPanelRect.left;
    const positionYRelativeToPanel = e.clientY - notesPanelRect.top;
    setContextMenu({
      show: true,
      position: {
        x: positionXRelativeToPanel,
        y: positionYRelativeToPanel,
      },
      selectedText: selectedText,
    });
  };
  useEffect(() => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height =
      textareaRef.current.scrollHeight + 6 + "px";
  }, [value]);

  useEffect(() => {
    if (autoFocusOnRender) {
      // move cursor to end, making cursor move to the end
      textareaRef.current.selectionStart = textareaRef.current.value.length;
      textareaRef.current.selectionEnd = textareaRef.current.value.length;

      textareaRef.current.focus();
    }
  }, [autoFocusOnRender]);

  const textareaRef = useRef(null);

  const replaceInstructionWithResult = (newValue) => {
    updateValue(newValue);
  };
  const closeContextMenu = () => {
    setContextMenu((prev) => {
      return { ...prev, show: false };
    });
  };
  return (
    <div className={parentClasses}>
      <textarea
        className={
          " no-scrollbar border-2 border-grey-400 outline-none rounded-md p-2 w-full min-h-[50px] h-max bg-gray-50 m-0 border-none resize-none " +
          (classes ? classes : "") +
          (showBoxShadow ? " shadow-lg " : "") +
          (minHeight ? minHeight : "")
        }
        onChange={handleChange}
        value={value}
        disabled={isDisabled}
        placeholder={placeHolder}
        style={style}
        ref={textareaRef}
        onContextMenu={handleContextMenu}
        rows={1}
      ></textarea>
      <PluginsDropdown
        show={contextMenu.show}
        position={contextMenu.position}
        selectedText={contextMenu.selectedText}
        userInput={value}
        pluginsDropdownRef={pluginsDropdownRef}
        closeContextMenu={closeContextMenu}
        updateValue={replaceInstructionWithResult}
      />
    </div>
  );
};

export default BlockTextArea;
