import "./App.css";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AnnotationPage,
  UploadPage,
  Home,
  InteractiveReadingPage,
  OutputsPage,
  TerminologiesPage,
  VideoGenInputsPage,
  VideoInputsListPage,
  VideoThemeConfigPage,
  VideoAnnotationPage,
  VideoOutputPage,
  DeckAnnotationPage,
  DeckOutputPage,
} from "./pages";
import { ShotsOutputPage } from "./components";

function App() {
  return (
    <div className="App">
      <Toaster position="top-center" />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/upload" element={<UploadPage />} />

          <Route path="/annotate/:resourceId" element={<AnnotationPage />} />

          <Route path="/outputs/:resourceId" element={<OutputsPage />} />

          <Route path="/videos-input-list" element={<VideoInputsListPage />} />
          <Route
            path="/video-inputs/:outputId"
            element={<VideoGenInputsPage />}
          />

          <Route path="/videos/:outputId" element={<VideoOutputPage />} />

          <Route path="/shots/:outputId" element={<ShotsOutputPage />} />

          <Route
            path="/interact/:outputId"
            element={<InteractiveReadingPage />}
          />

          <Route path="/terms/:outputId" element={<TerminologiesPage />} />

          <Route path="/decks/:outputId" element={<DeckOutputPage />} />

          <Route path="/video-config" element={<VideoThemeConfigPage />} />
          <Route
            path="/annotate-video/:resourceId"
            element={<VideoAnnotationPage />}
          />

          <Route
            path="/annotate-deck/:resourceId"
            element={<DeckAnnotationPage />}
          />

          <Route
            path="*"
            element={
              <div className="flex justify-center items-center h-[100vh]">
                <p className="text-6xl">404 Nound</p>
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
