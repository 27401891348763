import React from "react";
import { ModalBackground } from "../ModalBackground/ModalBackground";
import { TextArea } from "../TextArea/TextArea";
import { Button } from "../Button/Button";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addHighlightAnnotation,
  resetHighlightSelection,
  toggleHighlightInputModal,
  updateHighlightAnnotation,
} from "../../features";
import toast from "react-hot-toast";

const HighlightInputModal = () => {
  const dispatch = useDispatch();
  const {
    highlights: { originalSelectedText, selectedText, annotation },
  } = useSelector((state) => state);
  return (
    <ModalBackground>
      <div className="max-w-[530px] p-4 bg-[#c2d7f6] rounded-md">
        <h1 className="text-lg text-center mb-5 font-bold">Add Annotation</h1>
        <p className="mb-3 rounded-md p-3 max-h-[200px] overflow-y-auto no-scrollbar bg-[#e3f0ff]">
          {selectedText}
        </p>
        <TextArea
          classes={"w-[500px] text-black"}
          value={annotation}
          hasMinHeight={false}
          onChange={(e) =>
            dispatch(
              updateHighlightAnnotation({ newAnnotationText: e.target.value })
            )
          }
        />
        <div className="flex justify-end gap-5">
          <Button
            onClick={() => {
              dispatch(resetHighlightSelection());
              dispatch(toggleHighlightInputModal());
            }}
          >
            Discard
          </Button>
          <Button
            onClick={() => {
              if (!annotation?.trim()) {
                toast.error("Annotation cannot be empty");
                return;
              }
              dispatch(
                addHighlightAnnotation({
                  newAnnotation: {
                    text: selectedText,
                    annotation,
                    originalText: originalSelectedText,
                  },
                })
              );
              dispatch(toggleHighlightInputModal());
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </ModalBackground>
  );
};

export { HighlightInputModal };
