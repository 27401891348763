import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import ReactJson from "react-json-view";

const VideoGenInputsPage = () => {
  const [inputData, setInputData] = useState({});
  const { outputId } = useParams();
  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/get_output_details?output_id=${outputId}`
        );
        setInputData(res?.data);
      } catch (e) {
        setInputData({});
        toast.error("Something went wrong");
      }
    })();
  }, []);
  return (
    <div>
      {inputData?._id ? (
        <div>
          <p className="my-5 text-5xl text-center">
            {inputData?.outputFileName}
          </p>
          <div className="flex flex-col justify-center w-[80vw]">
            {/* <pre>{JSON.stringify(inputData, null, 2)}</pre> */}
            <ReactJson src={inputData} />
          </div>
        </div>
      ) : (
        <div>
          <p>Not found</p>
        </div>
      )}
    </div>
  );
};

export { VideoGenInputsPage };
