import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { AreaSelectionMenu } from "../AreaSelectionMenu/AreaSelectionMenu";
import { AnnotationMenubar } from "../AnnotationMenubar/AnnotationMenubar";
import { SectionsDropdown } from "../SectionsDropdown/SectionsDropdown";
import { findArrowPoints } from "../../utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelectedTool, updateTool, updateData } from "../../features";
import { Topbar } from "../Topbar/Topbar";

fabric.LineArrow = fabric.util.createClass(fabric.Line, {
  type: "lineArrow",
  initialize: function (element, options) {
    options || (options = {});
    this.callSuper("initialize", element, options);
  },
  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"));
  },
  _render: function (ctx) {
    this.callSuper("_render", ctx);
    if (this.width === 0 || this.height === 0 || !this.visible) return;
    ctx.save();
    var xDiff = this.x2 - this.x1;
    var yDiff = this.y2 - this.y1;
    var angle = Math.atan2(yDiff, xDiff);
    ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
    ctx.rotate(angle);
    ctx.beginPath();
    ctx.moveTo(5, 0);
    ctx.lineTo(-10, 7.5);
    ctx.lineTo(-10, -7.5);
    ctx.closePath();
    ctx.fillStyle = this.stroke;
    ctx.fill();
    ctx.restore();
  },
});

fabric.LineArrow.fromObject = function (object, callback) {
  callback &&
    callback(
      new fabric.LineArrow([object.x1, object.y1, object.x2, object.y2], object)
    );
};

fabric.LineArrow.async = true;

function OverlayCanvas({ toggleOverlay, canvasRef, fabricCanvasRef }) {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        toggleOverlay();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const overlayRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [menuCoords, setMenuCoords] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });
  const [showSectionsDropdown, setShowSectionsDropdown] = useState(false);
  // const [toolMetadata, setToolMetadata] = useState(null);

  let drawingShape = null;

  const { selectedTool, selectedToolData } = useSelector(
    (state) => state?.fabric
  );

  const dispatch = useDispatch();
  const updateMenuCoords = (drawingShape) => {
    if (!drawingShape) {
      return;
    }
    const rect = drawingShape.getBoundingRect();
    setShowMenu(true);
    const { top, left, width, height } = rect;
    setMenuCoords({ top, left, width, height });
  };

  const updateToolMetadata = (drawingShape) => {
    if (!drawingShape) return;
    switch (drawingShape?.type) {
      case "Rectangle":
        // data = drawingShape?.aCoords
        dispatch(
          updateData({
            data: {
              topLeft: drawingShape?.aCoords?.tl,
              bottomRight: drawingShape?.aCoords?.br,
            },
          })
        );
        break;
      case "Arrow":
        dispatch(
          updateData({
            data: {
              start: drawingShape?.aCoords?.tl,
              end: drawingShape?.aCoords?.br,
            },
          })
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    canvasRef.current.width = overlayRef.current.offsetWidth;
    canvasRef.current.height = overlayRef.current.offsetHeight;

    const fabricCanvas = new fabric.Canvas(canvasRef.current, {
      selection: false,
    });
    fabricCanvasRef.current = fabricCanvas;

    fabricCanvas.on("object:scaling", function (e) {
      // var obj = e.target;
      // setShowMenu(false);
      // updateToolMetadata(obj);
    });

    fabricCanvas.on("object:moving", function (e) {
      var obj = e.target;
      // Get canvas boundaries
      var canvasWidth = fabricCanvas.getWidth();
      var canvasHeight = fabricCanvas.getHeight();

      // Calculate object boundaries
      var objWidth = obj.width;
      var objHeight = obj.height;
      var objLeft = obj.left;
      var objTop = obj.top;

      var objRight = objLeft + objWidth;
      var objBottom = objTop + objHeight;

      // const {
      //   aCoords: { tl, br },
      // } = obj;

      // const { x: objLeft, y: objTop } = tl;
      // const { x: objRight, y: objBottom } = br;

      // Check if object is moving out of the canvas boundaries
      // var isMovingOutOfBoundaries = false;

      // Check if object is moving out of the left boundary
      if (objLeft < 0) {
        // isMovingOutOfBoundaries = true;
        obj.set({
          left: 0,
        });
        // fabricCanvas.renderAll();
      }

      // Check if object is moving out of the top boundary
      if (objTop < 0) {
        obj.set({
          top: 0,
        });
        // fabricCanvas.renderAll();
      }

      // Check if object is moving out of the right boundary
      if (objRight > canvasWidth - 4) {
        obj.set({
          left: canvasWidth - objWidth * obj?.zoomX - 2,
        });
      }

      // Check if object is moving out of the bottom boundary
      if (objBottom > canvasHeight - 4) {
        obj.set({
          top: canvasHeight - objHeight - 2,
        });
      }

      // If object is moving out of the boundaries, update its position
      // if (isMovingOutOfBoundaries) {
      //   obj.set({
      //     left: objLeft,
      //     top: objTop,
      //   });

      // Update canvas
      fabricCanvas.renderAll();
      // }

      // Hide menu when object is moved
      setShowMenu(false);
    });

    fabricCanvas.on("mouse:up", function (e) {
      var obj = e.target;
      if (obj) {
        updateToolMetadata(obj);
        updateMenuCoords(obj);
      }
    });
  }, []);

  const addShapeToMiddle = (shapeType) => {
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas) {
      fabricCanvas.clear();
      let metadata = null;
      switch (shapeType) {
        case "Rectangle":
          drawingShape = new fabric.Rect({
            type: "Rectangle",
            left: fabricCanvas.width / 2 - 50,
            top: fabricCanvas.height / 2 - 50,
            width: 100,
            height: 100,
            fill: "transparent",
            stroke: "#FB2576",
            // borderColor: "red",
            strokeWidth: 3.5,
            strokeUniform: true,
            controls: {
              ...fabric.Rect.prototype.controls,
              mtr: new fabric.Control({ visible: false }),
            },
          });
          metadata = drawingShape?.aCoords;
          break;
        case "Circle":
          drawingShape = new fabric.Circle({
            type: "Circle",
            left: fabricCanvas.width / 2,
            top: fabricCanvas.height / 2,
            radius: 50,
            fill: "transparent",
            stroke: "black",
            strokeWidth: 2,
            strokeUniform: true,
            controls: {
              ...fabric.Circle.prototype.controls,
              mtr: new fabric.Control({ visible: false }),
            },
          });
          metadata = { left: drawingShape.left, top: drawingShape.top };
          break;
        case "Arrow":
          drawingShape = new fabric.LineArrow([0, 0, 100, 100], {
            type: "Arrow",
            fill: "black",
            stroke: "black",
            strokeWidth: 2,
            strokeUniform: true,
          });
          drawingShape.set({
            x1: fabricCanvas.width / 2,
            y1: fabricCanvas.height / 2,
            x2: fabricCanvas.width / 2 + 100,
            y2: fabricCanvas.height / 2 + 100,
          });
          metadata = findArrowPoints(drawingShape) || { key: "Test" };
          break;
        default:
          break;
      }
      if (drawingShape) {
        fabricCanvas.add(drawingShape);
        fabricCanvas.setActiveObject(drawingShape);
        fabricCanvas.renderAll();
        updateMenuCoords(drawingShape);
        dispatch(
          setSelectedTool({ tool: shapeType, data: drawingShape?.aCoords })
        );
        updateToolMetadata(drawingShape);
      }
    }
  };

  // function addImageToMiddle(imageUrl) {
  //   const fabricCanvas = fabricCanvasRef.current;
  //   if (fabricCanvas) {
  //     fabric.Image.fromURL(imageUrl, function (img) {
  //       img.set({
  //         id: "image_" + imageUrl,
  //         width: fabricCanvas.width / 2,
  //         height: fabricCanvas.height / 2,
  //       });
  //       fabricCanvas.add(img).renderAll().setActiveObject(img);
  //     });
  //   }
  // }

  return (
    <div class="overlay" ref={overlayRef}>
      <canvas id="react-canvas" ref={canvasRef} />
      {showMenu && (
        <AreaSelectionMenu
          top={menuCoords.top}
          left={menuCoords.left}
          width={menuCoords.width}
          height={menuCoords.height}
          toggleOverlay={toggleOverlay}
          setShowSectionsDropdown={setShowSectionsDropdown}
          setShowMenu={setShowMenu}
          selectedTool={selectedTool}
          toolMetadata={selectedToolData}
          canvasRef={canvasRef}
          updateToolMetadata={updateToolMetadata}
        />
      )}
      {showSectionsDropdown && (
        <SectionsDropdown
          setShowSectionsDropdown={setShowSectionsDropdown}
          coordinates={{
            x: menuCoords.left + menuCoords.width,
            y: menuCoords.top + menuCoords.height,
          }}
          selectedTool={selectedTool}
          toolMetadata={selectedToolData}
          toggleOverlay={toggleOverlay}
          canvasRef={canvasRef}
          updateToolMetadata={updateToolMetadata}
        />
      )}
      <AnnotationMenubar
        selectedTool={selectedTool}
        // updateSelectedTool={updateSelectedTool}
        addShapeToMiddle={addShapeToMiddle}
      />
      {/* <Topbar /> */}
    </div>
  );
}

// export const addImageToMiddle = OverlayCanvas.addImageToMiddle;

export { OverlayCanvas };
