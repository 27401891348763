import { configureStore } from "@reduxjs/toolkit";
import {
  notesReducer,
  resourcesReducer,
  selectionReducer,
  pdfViewerReducer,
  fabricReducer,
  chatReducer,
  playgroundReducer,
  editTermsReducer,
  videoShotsReducer,
  highlightsReducer,
} from "../features";

export default configureStore({
  reducer: {
    notes: notesReducer,
    resources: resourcesReducer,
    selection: selectionReducer,
    pdfViewer: pdfViewerReducer,
    fabric: fabricReducer,
    chat: chatReducer,
    playground: playgroundReducer,
    editTerms: editTermsReducer,
    videoShots: videoShotsReducer,
    highlights: highlightsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
