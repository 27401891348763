import { useEffect, useRef, useState } from "react";
import PDFView from "../../PDFView";
import { useDispatch } from "react-redux";
import {
  createNewSection,
  resetSelection,
  setHighlightSelectedText,
  setPageNumber,
  setSelection,
  toggleHighlightInputModal,
  toggleShowOverlay,
  updateCurrentNote,
  updateTool,
} from "../../features";
import { Button } from "../Button/Button";
import { SectionsDropdown } from "../SectionsDropdown/SectionsDropdown";
import { nanoid } from "@reduxjs/toolkit";
import { AreaSelectionMenu } from "../AreaSelectionMenu/AreaSelectionMenu";
import { OverlayCanvas } from "../OverlayCaanvas/OverlayCanvas";
import { useSelector } from "react-redux";

let pdfView;
function loadPDFResource(pdfRendererRef, handlePDFEvent, sUrlPDF, sUrlJSON) {
  pdfView = new PDFView();
  pdfView.mount(pdfRendererRef);
  pdfView.onEvent = handlePDFEvent;
  pdfView.doLoadResources(sUrlPDF, sUrlJSON);
}

const PDFRenderer = ({
  canvasRef,
  pdfRendererRef,
  fabricCanvasRef,
  resourceUrl,
}) => {
  const tabsData = ["Chats", "Notes"];
  const [nPages, setPages] = useState(0);
  const [nPage, setPage] = useState(0);
  const [showSectionsDropdown, setShowSectionsDropdown] = useState(false);
  const [sectionsDropdownCoords, setSectionsDropdownCoords] = useState({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    loadPDFResource(
      pdfRendererRef.current,
      handlePDFEvent,
      // `https://storage.googleapis.com/tsoai-resources-data/resources/646fa564ae4796bcd4b31020.pdf`
      // "https://arxiv.org/abs/"
      // "https://arxiv.org/pdf/1706.03762v5.pdf"
      // "https://storage.googleapis.com/tsoai-resources-data/Lawn_Boy.pdf"
      resourceUrl
    );
  }, []);

  const dispatch = useDispatch();

  const showOverlay = useSelector((state) => state.pdfViewer.showOverlay);

  const sectionsList = useSelector((state) => state.notes.sectionsList);

  const getSelectionMenuItems = (newSectionsList) => {
    const menuItems = ["Add Highlight", "Create new section"];

    if (sectionsList?.length > 0) {
      menuItems.push("Add to section...");
    }
    return menuItems;
  };

  // const pdfRendererRef = useRef(null);

  const toggleOverlay = () => {
    // pdfView.toggleOverlay();
    dispatch(toggleShowOverlay());
    dispatch(resetSelection());
  };

  const handlePDFEvent = (msg, data) => {
    const menuItems = getSelectionMenuItems(sectionsList);
    switch (msg) {
      case "PAGE_CHANGED":
        setPage(data.nPage);
        setPages(data.nPages);
        dispatch(setPageNumber({ newPageNumber: data.nPage }));
        break;

      case "TERMINOLOGY_MORE_INFO_CLICKED":
        alert(JSON.stringify(data, null, 2));
        // postQuery(
        //   `/define ${data.term}`,
        //   "system",
        //   "command",
        //   data.page,
        //   data.bbox,
        //   data.term,Add to section
        //   { _id: data._id }
        // );
        break;

      case "TEXT_SELECTION_CONTEXT_MENU_ITEM_CLICKED":
        // alert("Menu click\n" + JSON.stringify(data, null, 2));
        // if (data.item === "Ask a Question") {
        //   let payload = {
        //     text: data.text,
        //     bbox: data.bbox,
        //   };
        //   dispatch({ type: "SET_QUERY_CONTEXT", payload: payload });
        // } else if (data.item === "Add to Note") {
        //   const botMessage = data.text;
        //   setNotes((prev) => {
        //     return prev + `${prev?.length ? "\n \n" : " "}` + botMessage;
        //   });

        //   if (!isEditorOpen) {
        //     setIsEditorOpen(true);
        //   }
        //   setIsEditorMinimize(false);
        // } else {
        //   const command = data.item.toLowerCase().replace(/\s+/g, "-");
        //   postQuery(
        //     `/${command} ${data.text}`,
        //     "system",
        //     "command",
        //     data.lt_page,
        //     data.bbox,
        //     data.text,
        //     {}
        //   );
        // }
        const { originalText, text, bbox, lt_page, x, y } = data;
        dispatch(
          setSelection({
            selectedText: text,
            boundingBox: bbox,
            pageNumber: lt_page,
          })
        );
        dispatch(updateTool({ tool: "Text" }));
        if (data.item === "Create new section") {
          let payload = {
            sectionId: nanoid(),
            blockId: nanoid(),
            type: "Text",
            context: {
              selectedText: text,
              boundingBox: bbox,
              pageNumber: lt_page,
            },
            content: text,
            title: "Untitled",
          };
          dispatch(createNewSection(payload));
        } else if (data.item === "Add to section...") {
          setShowSectionsDropdown(true);
          setSectionsDropdownCoords({ x: x, y: y });
        } else if (data.item === "Add Highlight") {
          console.log("Reached Add highlight : ", { data });
          dispatch(setHighlightSelectedText({ text, originalText }));
          dispatch(toggleHighlightInputModal());
          //   setSectionsDropdownCoords({ x: posLeft, y: posTop });
        }
        break;

      case "TEXT_SELECTION_CONTEXT_MENU_SHOWN":
        // Set the items for the text selection context menu dynamically
        // This can be called asynchronously from a network call success, but the menu will be invisible until then
        pdfView.setMenuItems(menuItems);
        break;

      case "AREA_SELECTION_CONTEXT_MENU_ITEM_CLICKED":
        pdfView.setMenuItems(menuItems);
        break;
      default:
        alert("Menu click\n" + JSON.stringify(data, null, 2));
        return;
    }
  };
  return (
    <div
      className="mr-1 rounded-lg border-1 border-dashed w-full border-slate-800/70"
      id="pdf-viewer-pane"
    >
      {showSectionsDropdown && (
        <SectionsDropdown
          coordinates={sectionsDropdownCoords}
          setShowSectionsDropdown={setShowSectionsDropdown}
          selectedTool={"Text"}
        />
      )}
      <div id="pdf-toolbar" className="m-0">
        <div>
          <Button>Dashboard</Button>
        </div>
        <div id="pdf-navigation" className="flex flex-row justify-center">
          <Button
            onClick={() => {
              pdfView.gotoPageRel(-1);
            }}
            className="border-slate-600 px-2 mx-1 rounded-full border-1"
          >
            Prev
          </Button>
          <div
            id="pdf-page-indicator"
            className="border-slate-600 px-2 mx-1 rounded border"
          >
            <div>{nPage}</div> <div>/</div> <div>{nPages}</div>
          </div>
          <Button
            onClick={() => pdfView.gotoPageRel(1)}
            className="border-slate-600 px-2 mx-1 rounded-full border-1"
          >
            Next
          </Button>
        </div>
        <div>
          <Button onClick={toggleOverlay}>
            {showOverlay ? "Close" : "Annotate"}
          </Button>
        </div>
      </div>
      <div
        id="pdf-viewer-placeholder"
        style={{ border: "1px solid #dddddd" }}
        ref={pdfRendererRef}
      >
        {showOverlay && (
          <OverlayCanvas
            toggleOverlay={toggleOverlay}
            canvasRef={canvasRef}
            fabricCanvasRef={fabricCanvasRef}
          />
        )}
      </div>
    </div>
  );
};

export { PDFRenderer };

export { pdfView as pdfView };
