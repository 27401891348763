import React, { useState } from "react";

const Dropdown = ({ options, defaultValue, value, setValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(defaultValue || "");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setValue(option);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        className="bg-white font-semibold py-2 px-4 rounded inline-flex items-center"
        onClick={toggleDropdown}
      >
        <span className="mr-2">{value || "Select an option"}</span>
        <svg
          className={`fill-current h-4 w-4 ${isOpen ? "-mb-1" : "mt-1"}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path fillRule="evenodd" d="M10 12l-6-6 1.5-1.5L10 9l4.5-4.5L16 6z" />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute left-0 mt-2 w-48 bg-white border rounded-lg shadow-lg">
          <ul>
            {options.map((option, index) => (
              <li
                key={index}
                className="block px-4 py-2 hover:bg-[#f0f0f0] cursor-pointer"
                onClick={() => handleOptionSelect(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export { Dropdown };
