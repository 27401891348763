import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDebounce, useDetectClick } from "../../hooks";
import toast from "react-hot-toast";

const SearchDropdown = ({ selectedTerm, setSelectedTerm }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const componentRef = useRef(null);

  // once a term is added , reset the input values
  useEffect(() => {
    if (!selectedTerm) {
      setSearchTerm("");
      setSearchResults([]);
    }
  }, [selectedTerm]);

  useEffect(() => {
    // clear selectedTerm if the user starts typing
    // !selectedTerm to avoid resetting when the user chooses something from the dropdown
    if (selectedTerm && !selectedTerm) {
      setSelectedTerm(null);
    }
  }, [searchTerm]);

  const handleOutsideClick = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    // if (selectedTerm) {
    //   return;
    // }
    const fetchSearchResults = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/search_terminologies`,
          {
            domain: "pharmacy",
            search_term: debouncedSearchTerm,
          }
        );
        setSearchResults(response.data.results);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong");
      }
    };
    console.log("From debounced search ");
    if (debouncedSearchTerm.trim().length > 2) {
      console.log("Show");
      setShowDropdown(true);
      fetchSearchResults();
    } else {
      console.log("hide");
      setShowDropdown(false);
      setSearchResults([]);
    }
  }, [debouncedSearchTerm]);

  // useDetectClick(componentRef, () => {
  //   setShowDropdown(false);
  // });

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleItemClick = (term) => {
    setSearchTerm(term?.term);
    setSelectedTerm(term);
    setShowDropdown(false);

    // setTimeout(() => {
    //   setShowDropdown(false);
    // }, 500);
  };
  return (
    <div className="relative w-[50%]" ref={componentRef}>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
        className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:border-blue-500"
        // onFocus={() => setShowDropdown(true)}
        // onBlur={() => setShowDropdown(false)}
      />

      <div className="absolute z-10 mt-2 w-full bg-white rounded max-h-48 overflow-y-auto">
        {loading && (
          <div className="flex justify-center py-5 border border-gray-300">
            Loading...
          </div>
        )}
        {showDropdown && searchResults.length > 0 && (
          <>
            {searchResults.map((result) => (
              <div
                key={result._id}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer border border-gray-300"
                onClick={() => handleItemClick(result)}
              >
                <p className="text-gray-800 font-semibold">{result.term}</p>
                <p className="text-gray-600">{result.definition}</p>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export { SearchDropdown };
