import React, { useEffect, useState } from "react";
import { getAllPdfs } from "../../utils";
import { Link } from "react-router-dom";
import { Button, PDFListRow } from "../index";

const PDFList = () => {
  // first fetch, with loader
  const [pdfs, setPdfs] = useState([]);
  useEffect(() => {
    (async () => {
      const pdfs = await getAllPdfs();
      setPdfs(pdfs);
    })();
  }, []);

  // polling without loader
  useEffect(() => {
    const myInterval = setInterval(fetchQuote, 5000);

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const fetchQuote = async () => {
    const pdfs = await getAllPdfs(false);
    setPdfs(pdfs);
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto min-w-full divide-y divide-gray-200">
        <thead>
          <tr className="bg-gray-50 text-left">
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Title
            </th>
            {/* <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Domain
            </th> */}

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Status
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Interactive Document
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Video
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Generate Deck
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Outputs
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {pdfs.map((ele) => (
            <PDFListRow pdfItem={ele} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { PDFList };
