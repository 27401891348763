import React from "react";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router-dom";

const ProcessingOverlay = ({ toggleOverlay }) => {
  const navigate = useNavigate();

  const handleGoHome = async () => {
    toggleOverlay();
    // navigate("/");
  };
  return (
    <div className="overlay flex justify-center items-center ">
      <div className="flex flex-col justify-center items-center gap-3 bg-[#f0f0f0] p-5 rounded relative">
        <h3 className="text-center font-bold text-2xl mb-3 mt-2">
          Processing...
        </h3>
        <p>
          Your request is being processed, you can check the status in dashboard
        </p>
        <Button onClick={handleGoHome}>Added to Process Queue</Button>
      </div>
    </div>
  );
};

export { ProcessingOverlay };
