// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 3px solid white;
  border-top: 3px solid black;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: spin 1.25s linear infinite;
}

.loader-full-screen {
  height: 80vh;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 450px) {
  .loader {
    width: 4rem;
    height: 4rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/loader.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,qCAAqC;AACvC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".loader-container {\n  height: 30vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.loader {\n  border: 3px solid white;\n  border-top: 3px solid black;\n  border-radius: 50%;\n  width: 2rem;\n  height: 2rem;\n  animation: spin 1.25s linear infinite;\n}\n\n.loader-full-screen {\n  height: 80vh;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@media screen and (max-width: 450px) {\n  .loader {\n    width: 4rem;\n    height: 4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
