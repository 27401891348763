import React, { useEffect, useRef, useState } from "react";
import { GrNext, GrPrevious } from "react-icons/gr";

function getSpriteImageUrl(thumbnailsText, chaptersUrl) {
  const url = new URL(chaptersUrl);
  const baseUrl = `${url.origin}${url.pathname.substring(
    0,
    url.pathname.lastIndexOf("/") + 1
  )}`;
  const lines = thumbnailsText.split("\n").filter((line) => line.trim() !== "");
  for (const line of lines) {
    if (line.includes("#xywh=")) {
      const [file] = line.split("#xywh=");
      return `${baseUrl}${file.trim()}`;
    }
  }
  return null;
}

function getClosestThumbnail(chapterStart, thumbnails) {
  let closestThumbnail = null;
  let minDiff = Infinity;

  thumbnails.forEach((thumbnail) => {
    // Calculate thumbnailStart 2 seconds greater than thumbnail.start
    const thumbnailStart =
      new Date(`1970-01-01T${thumbnail.start}Z`).getTime() + 2000; // adding 2000 milliseconds

    const chapterStartTime = new Date(`1970-01-01T${chapterStart}Z`).getTime();
    // Ensure closestThumbnail is greater than thumbnailStart
    if (thumbnailStart >= chapterStartTime) {
      const diff = thumbnailStart - chapterStartTime;

      // Choose the first thumbnail that meets the condition
      if (diff < minDiff) {
        minDiff = diff;
        closestThumbnail = thumbnail;
      }

      // Exit loop early once a suitable thumbnail is found
      return;
    }
  });

  return closestThumbnail;
}

function formatTimestamp(seconds, type) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  if (type === "string") {
    const hoursStr = hours > 0 ? `${hours} hr ` : "";
    const minutesStr = minutes > 0 ? `${minutes} min ` : "";
    const secondsStr = secs > 0 ? `${secs} sec` : "";
    return `${hoursStr}${minutesStr}${secondsStr}`.trim();
  } else if (type === "timeStamp") {
    const hoursStr = hours > 0 ? `${hours}:` : "";
    const minutesStr = hours > 0 ? String(minutes).padStart(2, "0") : minutes;
    const secondsStr = String(secs).padStart(2, "0");
    return `${hoursStr}${minutesStr}:${secondsStr}`;
  } else {
    throw new Error("Invalid type. Type should be string or timeStamp.");
  }
}

// function parseVTT(data, isThumbnail = false) {
//   const lines = data.split("\n").filter((line) => line.trim() !== "");
//   const entries = [];
//   let entry = {};

//   lines.forEach((line) => {
//     if (line.startsWith("WEBVTT")) {
//       return;
//     } else if (line.includes("-->")) {
//       const [start, end] = line.split(" --> ");
//       entry.start = start.trim();
//       entry.end = end.trim();
//     } else if (isThumbnail && line.includes("#xywh=")) {
//       const [file, coords] = line.split("#xywh=");
//       const [x, y, w, h] = coords.split(",").map(Number);
//       entry.sprite = { file: file.trim(), x, y, w, h };
//       entries.push(entry);
//       entry = {};
//     } else {
//       entry.label = line.trim();
//       if (!isThumbnail) {
//         entries.push(entry);
//         entry = {};
//       }
//     }
//   });

//   return entries;
// }

function parseVTT(data, isThumbnail = false) {
  const lines = data.split("\n").filter((line) => line.trim() !== "");
  const entries = [];
  let entry = {};

  // Function to convert timestamp to seconds
  const parseTimestamp = (timestamp) => {
    const [hours, minutes, seconds] = timestamp.split(":");
    const [secs, millis] = seconds.split(".");
    return (
      parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(secs)
      // +parseInt(millis) / 1000
    );
  };

  lines.forEach((line) => {
    if (line.startsWith("WEBVTT")) {
      return;
    } else if (line.includes("-->")) {
      const [start, end] = line.split(" --> ");
      entry.start = start.trim();
      entry.end = end.trim();

      if (!isThumbnail) {
        entry.startTimestamp = parseTimestamp(entry.start);
        entry.endTimestamp = parseTimestamp(entry.end);
        entry.duration = entry.endTimestamp - entry.startTimestamp;
      }
    } else if (isThumbnail && line.includes("#xywh=")) {
      const [file, coords] = line.split("#xywh=");
      const [x, y, w, h] = coords.split(",").map(Number);
      entry.sprite = { file: file.trim(), x, y, w, h };
      entries.push(entry);
      entry = {};
    } else {
      entry.label = line.trim();
      if (!isThumbnail) {
        entries.push(entry);
        entry = {};
      }
    }
  });

  return entries;
}

function ChaptersPreviewBar({ thumbnailsUrl, chaptersUrl, seekAndPlay }) {
  const [thumbnails, setThumbnails] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [spriteImageUrl, setSpriteImageUrl] = useState("");

  useEffect(() => {
    async function fetchVTT() {
      const [thumbnailsRes, chaptersRes] = await Promise.all([
        fetch(thumbnailsUrl),
        fetch(chaptersUrl),
      ]);

      const thumbnailsText = await thumbnailsRes.text();
      const chaptersText = await chaptersRes.text();

      const spriteImageUrl = getSpriteImageUrl(thumbnailsText, chaptersUrl);
      const thumbnailsData = parseVTT(thumbnailsText, true);
      const chaptersData = parseVTT(chaptersText);

      setSpriteImageUrl(spriteImageUrl);
      setThumbnails(thumbnailsData);
      setChapters(chaptersData);
    }

    fetchVTT();
  }, [thumbnailsUrl, chaptersUrl]);

  const containerRef = useRef(null);

  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  console.log({ isAtEnd, isAtStart });

  const updateScrollButtons = () => {
    if (containerRef.current) {
      console.log({ left: containerRef.current.scrollLeft });
      setIsAtStart(containerRef.current.scrollLeft === 0);

      console.log({
        left: containerRef.current.scrollLeft,
        cliWidth: containerRef.current.clientWidth,
        scrollWid: containerRef.current.scrollWidth,
      });
      setIsAtEnd(
        containerRef.current.scrollLeft + containerRef.current.clientWidth >=
          containerRef.current.scrollWidth
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.addEventListener("scroll", updateScrollButtons);
        updateScrollButtons();
        return () => {
          containerRef.current.removeEventListener(
            "scroll",
            updateScrollButtons
          );
        };
      }
    }, 1000);
  }, []);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  return (
    <div className="flex mt-10  w-[950px]  no-scrollbar self-start">
      {/* scroll to left */}
      <button
        className={`ml-1 ${isAtStart ? " cursor-not-allowed opacity-50" : ""}`}
        onClick={scrollLeft}
        disabled={isAtStart}
      >
        <div
          className={`mr-3 p-3 rounded-full bg-slate-800 text-slate-50 ${
            isAtStart ? " bg-slate-500" : ""
          }`}
        >
          <GrPrevious />
        </div>
      </button>
      <div ref={containerRef} className="flex overflow-x-scroll no-scrollbar">
        {chapters.map((chapter, index) => {
          const thumbnail = getClosestThumbnail(chapter.start, thumbnails);
          console.log(thumbnail);
          return (
            <div
              key={index}
              className="flex flex-col gap-[5px] justify-start items-center min-w-[150px] px-4 pt-5 hover:bg-gray-100 cursor-pointer rounded-md"
              onClick={() => seekAndPlay(chapter?.startTimestamp)}
            >
              {thumbnail && (
                <img
                  src={`${new URL(spriteImageUrl, thumbnailsUrl)}`}
                  style={{
                    objectPosition: `-${thumbnail.sprite.x}px -${thumbnail.sprite.y}px`,
                    //   minWidth: "100px",
                    minWidth: `${thumbnail.sprite.w}px`, //image gets cropped while using width
                    height: `${thumbnail.sprite.h}px`,
                    //   minWidth: `${thumbnail.sprite.w * 2}px`, // Adjusted scaling factor
                    //   height: `${thumbnail.sprite.h * 2}px`, // Adjusted scaling factor
                    objectFit: "none",
                  }}
                  alt="thumbnail"
                />
              )}
              <div className="text-xs text-blue-600 hover:text-blue-800 py-1 px-1 bg-[#def1ff]">
                {formatTimestamp(chapter?.startTimestamp, "timeStamp")}
              </div>
              {/* <div>{formatTimestamp(chapter?.duration, "string")}</div> */}
              <div className="line-clamp-2 text-center ">{chapter.label}</div>
            </div>
          );
        })}
      </div>

      {/* scroll to right */}

      <button
        className={`ml-1 ${isAtEnd ? " cursor-not-allowed opacity-50" : ""}`}
        onClick={scrollRight}
        disabled={isAtEnd}
      >
        <div
          className={`ml-3 p-3 rounded-full bg-slate-800 text-slate-50 ${
            isAtEnd ? " bg-slate-500" : ""
          }`}
        >
          <GrNext />
        </div>
      </button>
    </div>
  );
}

export { ChaptersPreviewBar };
