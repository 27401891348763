import React from "react";

const ModalBackground = ({ children }) => {
  return (
    <div className="flex justify-center items-center w-[100%] h-[100%] absolute z-[1001] top-0 left-0 bg-black bg-opacity-50">
      {children}
    </div>
  );
};

export { ModalBackground };
