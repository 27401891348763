import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, SearchDropdown } from "../../components";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setEditingTerm } from "../../features";

const TerminologiesPage = () => {
  const [terms, setTerms] = useState([]);
  // const [termId, setTermId] = useState("");
  const [matchWords, setMatchWords] = useState("");
  const [selectedTerm, setSelectedTerm] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/terminologies?output_id=${outputId}`
      );
      const terms = res.data;
      setTerms(terms);
      // setoutputs(newOutputs);
    })();
  }, []);

  const { outputId } = useParams();

  const deleteTerm = async (outputTermId) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/delete_output_terminology`,
        {
          data: {
            outputTermId: outputTermId,
            outputId: outputId,
          },
        }
      );
      const terms = res.data;
      setTerms(terms);
      toast.success("Deleted");
    } catch (e) {
      console.log({ Error: e });
      toast.error("Something went wrong");
    }
  };

  const handleUpdateTerm = (term) => {
    toast.success("WIP");
    // dispatch(setEditingTerm(term));
  };

  const handleAddTerm = async () => {
    if (!selectedTerm) {
      toast.error("Select a valid term from the dropdown");
      return;
    }

    if (matchWords.trim().length < 1) {
      toast.error("Enter a valid word to match");
      return;
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/add_term_to_output`,
        {
          domain: "pharmacy",
          outputId: outputId,
          termId: selectedTerm?._id,
          matchWords: matchWords,
        }
      );
      const terms = res.data;
      setTerms(terms);
      toast.success("Term added");
      setSelectedTerm(null);
      setMatchWords("");
    } catch (e) {
      console.log({ Error: e });
      toast.error(e?.detail);
    }
  };

  return (
    <div className="w-[80%] mx-auto flex items-center">
      <div>
        <h1 className="text-3xl text-center my-3">Manage terms : {outputId}</h1>
        <div>
          {terms.length === 0 ? (
            <p className="text-xl text-center my-3">No match :/</p>
          ) : (
            <div>
              <div className="flex justify-between my-5">
                <input
                  value={matchWords}
                  className="outline-none border-grey-700 border-2 p-1 rounded-md"
                  onChange={(e) => setMatchWords(e.target.value)}
                  placeholder="Word to match"
                />
                {/* <input
                  value={termId}
                  className="outline-none border-grey-700 border-2 p-1 rounded-md"
                  onChange={(e) => setTermId(e.target.value)}
                  placeholder="Term Id to match"
                /> */}
                <SearchDropdown
                  selectedTerm={selectedTerm}
                  setSelectedTerm={setSelectedTerm}
                />
                <Button onClick={handleAddTerm}>Add Term</Button>
              </div>
              <table className="table-fixed mb-[24px]">
                <thead>
                  <tr>
                    {/* <th className="border px-4 py-2">Id</th> */}
                    <th className="border px-4 py-2">Term</th>
                    {/* <th className="border px-4 py-2">termId</th> */}
                    <th className="border px-4 py-2">Matched Word</th>
                    <th className="border px-4 py-2">Definition</th>
                    <th className="border px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {[...terms].reverse().map((ele, idx) => {
                    const {
                      _id: id,
                      term,
                      term_id,
                      matched_words,
                      definition,
                    } = ele;
                    return (
                      <tr
                        key={id}
                        className={
                          "bg-gray-100 h-full " +
                          (idx % 2 === 0 ? " bg-blue-100" : "")
                        }
                      >
                        {/* <td className="border px-4 py-2">{id}</td> */}
                        <td className="border px-4 py-2">{term}</td>
                        {/* <td className="border px-4 py-2">{term_id}</td> */}
                        <td className="border px-4 py-2">
                          <div>
                            {matched_words.map((ele) => {
                              return <p className="py-1">{ele},</p>;
                            })}
                          </div>
                        </td>
                        <td className="border px-4 py-2">{definition}</td>
                        <td className="border px-4 py-2 flex gap-3 h-[100%] justify-center">
                          <Button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
                            onClick={() => handleUpdateTerm(ele)}
                          >
                            Update
                          </Button>
                          <Button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => deleteTerm(id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { TerminologiesPage };
