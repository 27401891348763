import React from "react";

const Checkbox = ({ isChosen, setIsChosen, children }) => {
  const toggleCheckbox = () => {
    setIsChosen(!isChosen);
  };

  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 accent-blue-400 cursor-pointer"
        checked={isChosen}
        onChange={toggleCheckbox}
      />

      <span className="ml-2" onClick={() => {}}>
        {children}
      </span>
    </label>
  );
};

export { Checkbox };
