import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedResources: [],
  resourceList: [
    {
      id: "123",
      name: "Selected text goes here",
      url: "www.google.com",
    },
  ],
};

const resourcesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    addResource: (state, action) => {
      state.resourceList?.push(action?.payload);
    },
    overWriteResourcesList: (state, action) => {
      state.resourceList = action?.payload?.newResourcesList;
    },
  },
});

export const { addResource,overWriteResourcesList } = resourcesSlice.actions;

export default resourcesSlice.reducer;
