import { createSlice, nanoid } from "@reduxjs/toolkit";
const initialState = {
  shotsSchema: [],
  shotsData: {},
};

const videoShotsSlice = createSlice({
  name: "videoShots",
  initialState,
  reducers: {
    // new

    setVideoShots: (state, action) => {
      const {
        payload: { newShotsData },
      } = action;
      state.shotsData = newShotsData;
    },

    updateVideoShotTitle: (state, action) => {
      const {
        payload: { shotId, newTitle },
      } = action;

      state.shotsData.shots = state.shotsData?.shots?.map((ele) => {
        if (ele.id === shotId) {
          return { ...ele, title: newTitle };
        }
        return ele;
      });
    },

    // updateVideoBlockVoiceover: (state, action) => {
    //   const {
    //     payload: { shotId, blockId, newVoiceoverText },
    //   } = action;
    //   state.shotsData.shots = state.shotsData?.shots?.map((shot) => {
    //     if (shot.id === shotId) {
    //       return shot?.blocks?.map((block) => {
    //         if (block.id === blockId) {
    //           return { ...block, voiceoverText: newVoiceoverText };
    //         }
    //         return block;
    //       });
    //     }
    //     return shot;
    //   });
    // },

    updateVideoBlockVoiceover: (state, action) => {
      const { shotId, blockId, newVoiceoverText } = action.payload;

      const updatedShots = state.shotsData.shots.map((shot) => {
        if (shot.id === shotId) {
          const updatedBlocks = shot.blocks.map((block) => {
            if (block.id === blockId) {
              return { ...block, voiceoverText: newVoiceoverText };
            } else {
              return block;
            }
          });

          return { ...shot, blocks: updatedBlocks };
        } else {
          return shot;
        }
      });

      return {
        ...state,
        shotsData: {
          ...state.shotsData,
          shots: updatedShots,
        },
      };
    },

    updateVideoBlockDisplayText: (state, action) => {
      const { shotId, blockId, newDisplayText } = action.payload;

      const updatedShots = state.shotsData.shots.map((shot) => {
        console.log({ shot });
        if (shot.id === shotId) {
          const updatedBlocks = shot.blocks.map((block) => {
            if (block.id === blockId) {
              console.log("Matched block : ", {...block, newDisplayText})
              return { ...block, displayText: newDisplayText };
            } else {
              return block;
            }
          });

          return { ...shot, blocks: updatedBlocks };
        } else {
          return shot;
        }
      });

      return {
        ...state,
        shotsData: {
          ...state.shotsData,
          shots: updatedShots,
        },
      };
    },

    // addVideoShot: (state, action) => {},
    setVideoShotsSchema: (state, action) => {
      const { payload } = action;
      state.shotsSchema = payload;
    },

    addVideoShot: (state, action) => {
      state.shotsData.shots = [
        ...state.shotsData?.shots,
        {
          id: nanoid(),
          title: "Title",
          shot_type: "VoiceoverSlideShot",
          blocks: [{ type: "text", displayText: "", voiceoverText: "" }],
        },
      ];
    },

    addVideoBlock: (state, action) => {
      // TODO : Implement
      return state;
    },

    deleteVideoShot: (state, action) => {
      const {
        payload: { id },
      } = action;
      state.shotsData.shots = state.shotsData?.shots?.filter(
        (ele) => ele?.id !== id
      );
    },

    deleteVideoBlock: (state, action) => {
      const {
        payload: { shotId, blockId },
      } = action;

      const updatedShots = state.shotsData.shots.map((shot) => {
        if (shot.id === shotId) {
          const updatedBlocks = shot.blocks.filter(
            (block) => block?.id !== blockId
          );
          return { ...shot, blocks: updatedBlocks };
        } else {
          return shot;
        }
      });

      return {
        ...state,
        shotsData: {
          ...state.shotsData,
          shots: updatedShots,
        },
      };
    },
  },
});

export const {
  setVideoShots,
  updateVideoShotTitle,
  updateVideoBlockDisplayText,
  updateVideoBlockVoiceover,
  setVideoShotsSchema,
  deleteVideoShot,
  deleteVideoBlock,
  addVideoShot,
  addVideoBlock,
} = videoShotsSlice.actions;

export default videoShotsSlice.reducer;
