import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const MarkdownRenderer = ({ content, classes, placeHolder, minHeight }) => {
  console.log("Markdown content : ", { content });
  const isContentEmpty = !content?.trim();

  return (
    <div
      className={
        "mb-3 bg-white shadow-md rounded-lg " + (classes ? classes : "")
      }
    >
      <div
        className={
          "rounded-md p-4 mb-1 w-full h-fit " + (minHeight ? minHeight : "")
        }
      >
        <ReactMarkdown
          className={
            "prose max-w-[100%] break-words " +
            (isContentEmpty ? "text-[#777]" : "")
          }
        >
          {isContentEmpty ? placeHolder : content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export { MarkdownRenderer };
