import React from "react";

const Button = ({
  children,
  type = "primary",
  style,
  classes,
  onClick,
  isFilled,
  noCursor,
  disabled,
}) => {
  let color = "neutral-700";

  const getBtnClasses = () => {
    let classes = "";
    if (isFilled) {
      classes = `bg-neutral-700 text-white hover:text-white `;
    } else {
      classes = `text-neutral-700 border border-neutral-700 hover:bg-neutral-700 hover:text-white `;
    }

    if (noCursor) {
      classes += " cursor-default ";
    }
    if (disabled) {
      classes += ` cursor-not-allowed hover:none `;
    }
    return classes;
  };

  return (
    <button
      className={`cursor-pointer py-2 px-4 rounded ${getBtnClasses()} ${
        classes ? classes : ""
      } `}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export { Button };
