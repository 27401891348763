import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  domain: "",
  isInRecordMode: false,
};

const playgroundSlice = createSlice({
  name: "playground",
  initialState,
  reducers: {
    setDomain: (state, action) => {
      const newDomain = action?.payload;
      state.domain = newDomain;
    },
    toggleRecordMode: (state, action) => {
      state.isInRecordMode = !state.isInRecordMode;
    },
  },
});

export const { setDomain, toggleRecordMode } = playgroundSlice.actions;

export default playgroundSlice.reducer;
