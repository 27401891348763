import React from "react";

const IconButton = ({
  children,
  type = "primary",
  style,
  classes,
  onClick,
  isFilled,
  noCursor,
  disabled,
  tooltip,
}) => {
  let color = "neutral-700";
  const getBtnClasses = () => {
    let classes = "";
    if (isFilled) {
      classes = `bg-neutral-700 text-white hover:text-white `;
    } else {
      classes = `text-neutral-700 hover:bg-neutral-700 hover:text-white `;
    }

    if (noCursor) {
      classes += "cursor-default ";
    }
    if (disabled) {
      classes += "cursor-not-allowed hover:none ";
    }
    return classes;
  };

  return (
    <div className="group relative flex justify-center">
      <button
        className={`cursor-pointer py-2 px-2 rounded ${getBtnClasses()} ${
          classes ? classes : ""
        } `}
        style={style}
        onClick={onClick}
      >
        {children}
      </button>
      {tooltip && (
        <span className="w-max absolute top-[-35px] scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
          {tooltip}
        </span>
      )}
    </div>
  );
};

export { IconButton };
