import React, { useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { Button } from "../Button/Button";
import { addIntro, addOutro } from "../../features/notes/notesSlice";
import { useDispatch } from "react-redux";

const AddSectionsButton = ({ id, hasIntro, hasOutro }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();

  const toggleShowDropdown = () => {
    setShowDropdown((prev) => !prev);
  };
  return (
    <div
      className="relative"
      onMouseEnter={toggleShowDropdown}
      onMouseLeave={toggleShowDropdown}
    >
      <FiPlusCircle className="cursor-pointer" />
      {showDropdown && (
        <div className="flex flex-col gap-2 absolute z-20 ml-[-90px] m-auto w-max p-2 bg-slate-200 rounded-lg ">
          <Button
            onClick={() => dispatch(addIntro({ id }))}
            disabled={hasIntro}
          >
            Add Intro
          </Button>
          <Button
            onClick={() => dispatch(addOutro({ id }))}
            disabled={hasOutro}
          >
            Add Outro
          </Button>
        </div>
      )}
    </div>
  );
};

export { AddSectionsButton };
