import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTool: "",
  selectedToolData: null,
  showFileUploadModal: false,
};

const fabricSlice = createSlice({
  name: "fabric",
  initialState,
  reducers: {
    setSelectedTool: (state, action) => {
      const {
        payload: { tool, data },
      } = action;
      state.selectedTool = tool;
      state.selectedToolData = data;
    },
    resetSelectedTool: (state, action) => {
      state.selectedTool = initialState.selectedTool;
      state.selectedToolData = initialState.selectedToolData;
    },
    updateTool: (state, action) => {
      const {
        payload: { tool },
      } = action;
      state.selectedTool = tool;
    },
    updateData: (state, action) => {
      const {
        payload: { data },
      } = action;
      state.selectedToolData = data;
    },
    toggleFileUploadModal: (state, action) => {
      state.showFileUploadModal = !state.showFileUploadModal;
    },
  },
});

export const {
  setSelectedTool,
  resetSelectedTool,
  updateTool,
  updateData,
  toggleFileUploadModal,
} = fabricSlice.actions;

export default fabricSlice.reducer;
