import React from "react";

const ButtonWithTooltip = ({
  tooltipPosition = "right",
  buttonContent,
  id,
  tooltipContent,
}) => {
  //tooltipPosition can top/bottom/left/right
  return (
    <div>
      <button
        data-tooltip-target={`tooltip-${tooltipPosition}-${id}`}
        data-tooltip-placement={`${tooltipPosition}`}
        type="button"
        class="ms-3 mb-2 md:mb-0 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Tooltip {tooltipPosition}
      </button>

      <div
        id={`tooltip-${tooltipPosition}-${id}`}
        role="tooltip"
        class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
      >
        Tooltip on {tooltipPosition}
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </div>
  );
};

export { ButtonWithTooltip };
