import React from "react";

const Input = ({
  value,
  onChange,
  classes,
  isDisabled,
  placeHolder,
  style,
  showBoxShadow,
  handleBlur,
  autoFocusOnRender,
  minHeight,
  eleRef,
}) => {
  return (
    <input
      value={value}
      onChange={onChange}
      className={"p-1 outline-none " + (classes ? classes : "")}
      disabled={isDisabled}
      placeHolder={placeHolder}
      ref={eleRef}
    />
  );
};

export { Input };
