import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showOverlay: false,
  pageNumber: 0,
  dimension: {
    height: 1,
    width: 1,
  },
};

const pdfViewerSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    toggleShowOverlay: (state, action) => {
      state.showOverlay = !state.showOverlay;
    },
    setPageNumber: (state, action) => {
      const { newPageNumber } = action?.payload;
      state.pageNumber = newPageNumber;
    },
    updateDimension: (state, action) => {
      const { newDimension } = action?.payload;
      state.dimension = newDimension;
    },
  },
});

export const { toggleShowOverlay, setPageNumber, updateDimension } =
  pdfViewerSlice.actions;

export default pdfViewerSlice.reducer;
