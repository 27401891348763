export const textTracks = [
  //   {
  //     src: "https://files.vidstack.io/sprite-fight/subs/english.vtt",
  //     label: "English",
  //     language: "en-US",
  //     kind: "subtitles",
  //     default: true,
  //   },
  //   {
  //     src: "https://files.vidstack.io/sprite-fight/subs/spanish.vtt",
  //     label: "Spanish",
  //     language: "es-ES",
  //     kind: "subtitles",
  //   },
  // Chapters
  {
    // src: "https://storage.googleapis.com/tsoai-resources-data/terzepatide_chapters.vtt",
    src: "https://storage.googleapis.com/tsoai-resources-data/lowe_chapters.vtt",
    kind: "chapters",
    language: "en-US",
    default: true,
  },
];
