import { nanoid } from "@reduxjs/toolkit";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addBlockToSection, resetSelection } from "../../features";
import { useDetectClick } from "../../hooks";
import {
  findRectangleCorners,
  getArrowPoints,
  getToolMetadata,
} from "../../utils";

const SectionsDropdown = ({
  coordinates,
  setShowSectionsDropdown,
  // selectedTool,
  toolMetadata,
  toggleOverlay,
  canvasRef,
}) => {
  const sectionsList = useSelector((state) => state?.notes?.sectionsList);
  const sectionsDropdownRef = useRef(null);
  const { x, y } = coordinates;
  const selectedContext = useSelector((state) => state?.selection?.context);
  const dispatch = useDispatch();
  const pageNumber = useSelector((state) => state.pdfViewer.pageNumber);
  const handleOutsideClick = () => {
    setShowSectionsDropdown(false);
    dispatch(resetSelection());
  };
  useDetectClick(sectionsDropdownRef, handleOutsideClick);

  const { selectedToolData, selectedTool } = useSelector(
    (state) => state?.fabric
  );
  // const getContext = (selectedTool, selectedContext) => {

  //   return selectedContext;
  // };
  const handleAddBlock = (sectionId) => {
    if (toggleOverlay) {
      toggleOverlay();
    }
    const blockData = {
      id: nanoid(),
      type: selectedTool,
      // context: getToolMetadata(
      //   selectedTool,
      //   { ...selectedContext, ...toolMetadata },
      //   canvasRef
      // ),
      context: {
        selectedTool,
        // ...getToolMetadata(selectedTool),
        ...selectedToolData,
        // ...toolMetadata,
        ...selectedContext,
        pageNumber,
      },
      content: selectedContext?.selectedText
        ? selectedContext?.selectedText
        : "",
    };
    dispatch(addBlockToSection({ sectionId, blockData }));
    setShowSectionsDropdown(false);
  };

  let calculatedHeight = window.innerHeight - sectionsList.length * 60;
  const style = {
    top: Math.min(y, calculatedHeight),
    left: x,
  };
  return (
    <div
      className={"bg-[#ffeac3] px-3 py-1 w-max rounded-lg absolute z-10 "}
      style={style}
      ref={sectionsDropdownRef}
    >
      {sectionsList.map((note) => {
        const { title, id } = note;
        return (
          <p
            className="py-2 my-3 px-4 bg-[#ffc75d] rounded-lg cursor-pointer"
            key={id}
            onClick={() => handleAddBlock(id)}
          >
            {title}
          </p>
        );
      })}
    </div>
  );
};

export { SectionsDropdown };
