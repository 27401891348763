import React from "react";
import { ThemeConfigForm } from "../../components";

const schema = [
  {
    type: "string",
    title: "Background Color",
    default: "#000000",
  },
  {
    type: "string",
    title: "Foreground Color",
  },
  {
    type: "string",
    title: "Annotation Color",
    default: "#EBF400",
  },
  {
    type: "string",
    title: "Voiceover Voice",
    default: "en-US-JennyNeural",
  },
  {
    type: "string",
    title: "Voiceover Style",
    default: "cheerful",
  },
  {
    type: "number",
    title: "Prosody Rate",
    default: 0.9,
  },
];

const VideoThemeConfigPage = () => {
  return <ThemeConfigForm />;
};

export { VideoThemeConfigPage };
