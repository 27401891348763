import { current, nanoid } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { createNewSection, toggleHighlightInputModal } from "../../features";
import { useDispatch } from "react-redux";
import {
  findRectangleCorners,
  getArrowPoints,
  getToolMetadata,
} from "../../utils";

const AreaSelectionMenu = ({
  toggleOverlay,
  left,
  top,
  width,
  height,
  setShowSectionsDropdown,
  setShowMenu,
  selectedTool,
  toolMetadata,
  canvasRef,
}) => {
  const isOverlayPresent = useSelector((state) => state.pdfViewer.showOverlay);
  const context = useSelector((state) => state.selection.context);
  const sectionsList = useSelector((state) => state.notes.sectionsList);
  // const fabricState = useSelector((state) => state?.fabric);
  // const { selectedToolData } = fabricState;
  const dispatch = useDispatch();

  const topCorrected = Math.min(
    canvasRef?.current?.clientHeight - 100,
    top + height
  );
  const leftCorrected = Math.min(
    canvasRef?.current?.clientWidth - 100,
    left + width
  );

  const posLeft = leftCorrected + "px",
    posTop = topCorrected + "px",
    right = top + width,
    bottom = left - height;

  const style = {
    position: "absolute",
    visibility: isOverlayPresent ? "visible" : "hidden",
    left: posLeft ? posLeft : "auto",
    top: posTop ? posTop : "auto",
  };

  const pageNumber = useSelector((state) => state.pdfViewer.pageNumber);

  const getContext = () => {
    if (selectedTool === "Text") {
      return context;
    }
    return {
      ...toolMetadata,
      pageNumber,
    };
  };

  const handleClick = (data) => {
    console.log("handleClick");
    if (data === "Create new section") {
      let payload = {
        sectionId: nanoid(),
        blockId: nanoid(),
        // context: {
        //   selectedText: selectedTool === "Text" ? context?.selectedText : ``,
        //   ...toolMetadata,
        //   pageNumber: pageNumber,
        // },
        context: getContext(),
        title: "Untitled",
        type: selectedTool,
        // toolMetadata: getMetadata(selectedTool, toolMetadata),
      };
      dispatch(createNewSection(payload));
      toggleOverlay();
    } else if (data === "Add to section...") {
      setShowMenu(false);
      setShowSectionsDropdown(true);
      //   setSectionsDropdownCoords({ x: posLeft, y: posTop });
    }
  };

  return (
    <div id="area-selection-menu" style={style} className="w-max ">
      <div id="pdf-menu-items">
        <div
          className="pdf-menu-item"
          onClick={() => handleClick("Create new section")}
        >
          Create new section
        </div>
        <div className="pdf-menu-item-separator"></div>
        {sectionsList?.length > 0 && (
          <div
            className="pdf-menu-item"
            onClick={() => handleClick("Add to section...")}
          >
            Add to section...
          </div>
        )}
      </div>
    </div>
  );
};

export { AreaSelectionMenu };
