import React, { useRef, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { Block } from "../Block/Block";
import { MdModeEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { Input } from "../Input/Input";
import { useDispatch } from "react-redux";
import { addBlockToSection, deleteSection, updateTitle } from "../../features";
import { MdDelete } from "react-icons/md";
import { smartGenerate } from "../../utils";
import { nanoid } from "@reduxjs/toolkit";
import { IconButton } from "../IconButton/IconButton";
import { RiRobot2Fill } from "react-icons/ri";
import { useDetectClick } from "../../hooks";
import { MdDragIndicator } from "react-icons/md";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { rearrangeBlocks } from "../../features/notes/notesSlice";
import toast from "react-hot-toast";
import { Tooltip } from "../Tooltip/Tooltip";
import { IntroOutroBlock } from "../IntroOutroBlock/IntroOutroBlock";
import { FiPlusCircle } from "react-icons/fi";
import { Button } from "../Button/Button";
import { AddSectionsButton } from "../AddSectionsButton/AddSectionsButton";

const Section = ({
  id,
  title,
  context,
  blocks,
  guidelines,
  dragProps,
  length,
}) => {
  //   const { selectedText = "", boundingBox = [], pageNumber = -1 } = context;
  const [showGuidelines, setShowGuidelines] = useState(true);
  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const dispatch = useDispatch();
  const titleInputRef = useRef(null);

  const hasIntro = blocks && blocks[0]?.type === "Intro";
  const hasOutro = blocks && blocks[blocks?.length - 1]?.type === "Outro";
  // const hasIntro = blocks && blocks.length > 0 && blocks[0].type === "Intro";
  // const hasOutro =
  //   blocks && blocks.length > 0 && blocks[blocks.length - 1].type === "Outro";

  const toggleShowGuidelines = () => {
    setShowGuidelines((prev) => !prev);
  };
  const toggleTitleEdit = () => {
    setIsTitleEdit((prev) => !prev);

    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  };
  const handleTitleUpdate = (e) => {
    dispatch(updateTitle({ sectionId: id, updatedTitle: e.target.value }));
  };
  const handleDeleteSection = () => {
    if (length < 2) {
      toast.error("Sections can't be empty!");
      return;
    }
    dispatch(deleteSection({ sectionId: id }));
  };
  const handleSmartGenerate = async (guideline) => {
    const response = await smartGenerate(guideline);
    const { task, result } = response;
    const blockData = {
      id: nanoid(),
      content: result,
      type: "Auto-gen",
      context: {
        boundingBox: [],
        selectedText: task,
        pageNumber: -1,
      },
    };
    dispatch(addBlockToSection({ sectionId: id, blockData }));
  };
  useDetectClick(titleInputRef, () => {
    if (isTitleEdit) {
      setIsTitleEdit(false);
    }
  });

  const getBlockComponent = (args) => {
    const { blockId, type, selectedText, content, editing, placeHolder } = args;
    console.log("GetBlockComponents : ", args);
    switch (type) {
      case "Text":
        console.log("Text : ", args);
        return (
          <Block
            id={blockId}
            sectionId={id}
            selectedText={selectedText}
            type={type}
            content={content}
            editing={editing}
            placeHolder={placeHolder}
          />
        );
      case "Intro":
      case "Outro":
        console.log("Intro/Outro : ", args);
        return (
          <IntroOutroBlock
            id={blockId}
            sectionId={id}
            selectedText={selectedText}
            type={type}
            content={content}
            editing={editing}
            placeHolder={placeHolder}
          />
        );
      default:
        console.log("Text : ", args);
        return (
          <Block
            id={blockId}
            sectionId={id}
            selectedText={type}
            type={type}
            content={content}
            editing={editing}
            placeHolder={placeHolder}
          />
        );
    }
  };
  // const onDragEnd = (result) => {
  //   if (!result.destination) return;

  //   const items = Array.from(blocks);
  //   const [reorderedItem] = items.splice(result.source.index, 1);
  //   items.splice(result.destination.index, 0, reorderedItem);
  //   dispatch(rearrangeBlocks({ newOrder: items, sectionId: id }));
  //   //  setTasks(items);
  // };
  return (
    <div key={id} className="bg-[#f1eded] pt-3 pb-1 rounded-lg">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          {dragProps && (
            <div {...dragProps} className="ml-2">
              <MdDragIndicator />
            </div>
          )}
          {isTitleEdit ? (
            <Input
              value={title}
              onChange={handleTitleUpdate}
              classes={" ml-2 "}
              eleRef={titleInputRef}
            />
          ) : (
            <h2 className=" text-xl font-bold ml-2">{title}</h2>
          )}
          <div className="cursor-pointer">
            {isTitleEdit ? (
              <div>
                <FaCheck onClick={toggleTitleEdit} />
              </div>
            ) : (
              <div>
                <MdModeEdit onClick={toggleTitleEdit} />
              </div>
            )}
          </div>
        </div>
        <div className="mr-3 flex justify-center items-center gap-3">
          {guidelines && guidelines.length > 0 && (
            <FaRegCircleQuestion
              onClick={() => setShowGuidelines(true)}
              className="cursor-pointer"
            />
          )}
          <AddSectionsButton id={id} hasIntro={hasIntro} hasOutro={hasOutro} />
          <MdDelete className="cursor-pointer" onClick={handleDeleteSection} />
        </div>
      </div>

      {/* {title && <hr className="border-stone-500 my-2" />} */}
      {guidelines && guidelines.length > 0 && showGuidelines && (
        <>
          <hr className="border-stone-500 my-2" />
          <div className="bg-[#fcf9dc] p-3 rounded-lg relative mx-2 my-3 ">
            <IoCloseCircleOutline
              className="absolute right-1 top-1 h-[20px] w-[20px] cursor-pointer"
              onClick={toggleShowGuidelines}
            />
            <div className="mt-2">
              {guidelines?.map((ele) => (
                <div
                  className="flex flex-row justify-between items-center"
                  key={ele}
                >
                  <p className="m-3">- {ele}</p>
                  <Tooltip message="AI generate">
                    <IconButton
                      onClick={() => handleSmartGenerate(ele)}
                      classes={" self-end "}
                    >
                      <RiRobot2Fill />
                    </IconButton>
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {/* TODO: implement toggle view for context */}
      {context?.selectedText && <p>{context?.selectedText}</p>}
      {blocks?.map((block, index) => {
        const {
          id: blockId,
          context: { selectedText = "", pageNumber = -1, boundingBox = [] },
          placeHolder,
          content,
          editing,
          type,
        } = block;
        return (
          <div key={blockId}>
            <hr className="border-stone-500 my-2" />
            {getBlockComponent({
              blockId,
              type,
              selectedText,
              content,
              editing,
              placeHolder,
            })}
          </div>
        );
      })}

      {/* <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="blocks">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {blocks?.map((block, index) => {
                const {
                  id: blockId,
                  context: {
                    selectedText = "",
                    pageNumber = -1,
                    boundingBox = [],
                  },
                  content,
                  editing,
                } = block;

                return (
                  <Draggable key={blockId} draggableId={blockId} index={index}>
                    {(provided) => (
                      <div
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <hr className="border-stone-500 my-2" />
                        <Block
                          id={blockId}
                          sectionId={id}
                          selectedText={selectedText}
                          content={content}
                          editing={editing}
                          dragProps={{ ...provided.draggableProps }}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext> */}
    </div>
  );
};

export { Section };
