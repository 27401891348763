import React, { useEffect, useState } from "react";

const componentsMap = {
  text: ({ label, description, placeholder }) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <p className="text-xs text-gray-500">{description}</p>
      <input
        type="text"
        placeholder={placeholder}
        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
      />
    </div>
  ),
  number: ({ label, description, placeholder }) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <p className="text-xs text-gray-500">{description}</p>
      <input
        type="number"
        placeholder={placeholder}
        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
      />
    </div>
  ),
  textarea: ({ label, description, placeholder }) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <p className="text-xs text-gray-500">{description}</p>
      <textarea
        placeholder={placeholder}
        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        rows="3"
      ></textarea>
    </div>
  ),
  color: ({ label, description }) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <p className="text-xs text-gray-500">{description}</p>
      <input
        type="color"
        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
      />
    </div>
  ),
};
const schema = [
  {
    inputType: "color",
    label: "Background Color",
    default: "#000000",
  },
  {
    inputType: "textarea",
    label: "Foreground Color",
  },
  {
    inputType: "textarea",
    label: "Annotation Color",
    default: "#EBF400",
  },
  {
    inputType: "textarea",
    label: "Voiceover Voice",
    default: "en-US-JennyNeural",
  },
  {
    inputType: "textarea",
    label: "Voiceover Style",
    default: "cheerful",
  },
  {
    inputType: "number",
    label: "Prosody Rate",
    default: 0.9,
  },
];

const ThemeConfigForm = () => {
  // const [schema, setSchema] = useState([]);

  // useEffect(() => {
  //   fetch("http://localhost:8000/schema")
  //     .then((response) => response.json())
  //     .then((data) => setSchema(data));
  // }, []);

  return (
    <form className="p-4">
      {schema.map((field, index) => {
        const Component = componentsMap[field.inputType];
        return Component ? <Component key={index} {...field} /> : null;
      })}
    </form>
  );
};

export { ThemeConfigForm };
