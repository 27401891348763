import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  context: { selectedText: "", pageNumber: -1, boundingBox: [] },
};

const selectionSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setSelection: (state, action) => {
      const {
        payload: { selectedText, boundingBox, pageNumber },
      } = action;
      state.context = {
        selectedText,
        boundingBox,
        pageNumber,
      };
    },
    resetSelection: (state, action) => {
      state.context = {
        selectedText: "",
        pageNumber: -1,
        boundingBox: [],
      };
    },
  },
});

export const { setSelection, resetSelection } = selectionSlice.actions;

export default selectionSlice.reducer;
