import React, { useState } from "react";
import BlockTextArea from "../BlockTextArea/BlockTextArea";
import { useDispatch } from "react-redux";
import {
  deleteBlock,
  resetEditingBlock,
  setEditingBlock,
  updateBlockNote,
} from "../../features";
import { IoTriangleSharp } from "react-icons/io5";
import { MarkdownRenderer } from "../MarkdownRenderer/MarkdownRenderer";
import { MdDelete, MdDragIndicator } from "react-icons/md";
import { useDetectClick } from "../../hooks";
import { useSelector } from "react-redux";
import { FiEye } from "react-icons/fi";
import { Tooltip } from "../Tooltip/Tooltip";

const Block = ({ id, selectedText, content, sectionId, dragProps, type }) => {
  const [showSelectedText, setShowSelectedText] = useState(false);
  const userInputRef = React.useRef(null);
  const dispatch = useDispatch();
  const updateBlockContent = (newValue) => {
    dispatch(
      updateBlockNote({ sectionId, blockId: id, updatedNote: newValue })
    );
  };

  const handleDeleteBlock = () => {
    dispatch(deleteBlock({ sectionId, blockId: id }));
  };
  const handleDisplaySelectedText = () => {
    setShowSelectedText((prev) => !prev);
  };
  const editingBlockId = useSelector((state) => state?.notes?.editingBlockId);
  const isEditing = id === editingBlockId;

  const handleClick = () => {
    if (editingBlockId !== id) {
      dispatch(setEditingBlock({ blockId: id }));
    }
  };

  useDetectClick(userInputRef, () => {
    if (isEditing) {
      dispatch(resetEditingBlock({ blockId: id }));
    }
  });
  const textAreaClasses = "mx-0 px-5 py-3 pt-5 min-h-[35vh] leading-[28px] ";
  return (
    <div key={id}>
      {dragProps && (
        <div {...dragProps} className="ml-2">
          <MdDragIndicator />
        </div>
      )}
      {/* <div className="flex flex-row items-between gap-2 px-1 bg-[#f6fcff] rounded-0 m-2 rounded-lg">
        <div className="">
          <IoTriangleSharp
            className={
              "w-[10px] mt-4 text-[#777] cursor-pointer" +
              (showSelectedText ? " rotate-180 " : " rotate-90 ")
            }
            onClick={handleDisplaySelectedText}
          />
        </div>
        <div className="flex items-center justify-between w-[100%]">
          <p
            className={
              "my-3 max-h-[300px] overflow-y-scroll italic text-[#777] no-scrollbar w-[90%] " +
              (showSelectedText ? "" : "truncate ")
            }
          >
            {type === "Text" ? selectedText : type}
          </p>
          <FiEye
            className={
              " cursor-pointer " +
              (type === "Text" || type === "Auto-gen" ? " mr-[25px]" : " mr-2")
            }
          />
        </div>
      </div> */}
      <div onClick={handleClick} ref={userInputRef} className="relative">
        <div className="flex gap-2 absolute cursor-pointer top-2 right-3 ">
          <Tooltip message={selectedText} classes={"max-w-[500px]"}>
            <FiEye className="z-10" />
          </Tooltip>
          <div onClick={handleDeleteBlock} className=" z-10 ">
            <MdDelete />
          </div>
        </div>
        {isEditing ? (
          <div className="mx-2 mt-2">
            <BlockTextArea
              value={content}
              updateValue={updateBlockContent}
              classes={textAreaClasses}
              showBoxShadow={true}
              autoFocusOnRender={true}
              minHeight={" min-h-[10vh] "}
            />
          </div>
        ) : (
          <div className=" rounded-lg my-2 relative mx-2 z-0">
            <MarkdownRenderer
              content={content}
              classes={" shadow-lg px-0 py-0 cursor-text"}
              placeHolder={"Start adding your notes here..."}
              minHeight={" min-h-[10vh] "}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { Block };
