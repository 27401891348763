import React, { useState } from "react";
import { PiRectangle } from "react-icons/pi";
import { MdArrowOutward } from "react-icons/md";
import { FaRegCircle } from "react-icons/fa";

const AnnotationMenubar = ({
  selectedTool,
  // updateSelectedTool,
  addShapeToMiddle,
  updateToolMetadata,
}) => {
  const tools = [
    {
      name: "Rectangle",
    },
    // {
    //   name: "Circle",
    // },
    {
      name: "Arrow",
    },
  ];

  const getComponent = (type) => {
    switch (type) {
      case "Rectangle":
        return <PiRectangle />;
      case "Arrow":
        return <MdArrowOutward />;
      case "Circle":
        return <FaRegCircle />;
      default:
        return null;
    }
  };

  const handleShapeClick = (tool) => {
    // updateSelectedTool(tool);
    addShapeToMiddle(tool);
  };
  return (
    <div className="flex flex-col h-[100%] justify-center z-[1000] absolute top-0 left-0">
      <div className="bg-[#f1eded] rounded-tr-lg rounded-br-lg">
        {tools.map((tool) => {
          const { name } = tool;
          const isSelected = selectedTool === name;
          const bgColor = isSelected ? " bg-[#fff05b] " : " bg-[#fffde8] ";
          return (
            <div
              className={
                " p-2 m-3 cursor-pointer rounded-lg border border-slate-500 bg-[#fffde8] "
              }
              onClick={() => handleShapeClick(name)}
              key={name}
            >
              {getComponent(name)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { AnnotationMenubar };
