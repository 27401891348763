import React, { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { useDispatch } from "react-redux";
import {
  addNote,
  toggleEditNode,
  resetCurrentNote,
  updateNote,
  deleteNote,
} from "../../features";
import { NoteTextBox } from "../NoteTextBox/NoteTextBox";
import toast from "react-hot-toast";
import { nanoid } from "@reduxjs/toolkit";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MarkdownRenderer } from "../MarkdownRenderer/MarkdownRenderer";

const NoteCard = ({ selectedText, editing, isNew, bbox, page, note, id }) => {
  const [userNote, setUserNote] = useState("");
  useEffect(() => {
    const noteValue = editing ? note : "";
    setUserNote(noteValue);
  }, [editing]);
  const dispatch = useDispatch();
  const handleSubmitNote = () => {
    if (!userNote.trim()) {
      toast.error("Note cannot be empty!");
      return;
    }
    dispatch(
      addNote({
        selectedText,
        note: userNote,
        bbox,
        page,
        id: nanoid(),
      })
    );
    dispatch(resetCurrentNote());
  };
  const handleUpdateNote = (id) => {
    if (!userNote.trim()) {
      toast.error("Note cannot be empty!");
      return;
    }
    dispatch(updateNote({ id, note: userNote }));
  };
  const updateEditMode = (id) => {
    dispatch(toggleEditNode({ id: id }));
  };
  const handleDeleteNote = (id) => {
    dispatch(deleteNote({ id: id }));
  };
  const handleDiscardNewNote = () => {
    dispatch(resetCurrentNote());
  };
  return (
    <div
      className={(isNew ? "bg-slate-200" : "bg-slate-400") + " p-4 rounded-lg"}
    >
      {!editing && !isNew && (
        <div className="flex justify-end gap-3 mb-3">
          <FaEdit
            className="cursor-pointer"
            onClick={() => updateEditMode(id)}
          />
          <MdDelete
            className="cursor-pointer"
            onClick={() => handleDeleteNote(id)}
          />
        </div>
      )}
      {isNew && (
        <div className="flex justify-end gap-3 mb-1">
          <IoIosCloseCircleOutline
            className="cursor-pointer text-2xl"
            onClick={() => handleDiscardNewNote(id)}
          />
        </div>
      )}
      <p className="mb-4 break-words	">{selectedText}</p>
      {!editing && !isNew && (
        <MarkdownRenderer
          className="cursor-pointer"
          onClick={() => handleDeleteNote(id)}
          content={note}
        />
      )}
      {editing && (
        <div>
          <NoteTextBox
            value={userNote}
            setValue={setUserNote}
            placeHolder={"Type your notes here"}
          />
          <div className="flex flex-col items-end">
            <Button onClick={() => handleUpdateNote(id)}>Update Note</Button>
          </div>
        </div>
      )}
      {isNew && (
        <div>
          <NoteTextBox
            value={userNote}
            setValue={setUserNote}
            placeHolder={"Type your notes here"}
          />
          <div className="flex flex-col items-end">
            <Button onClick={handleSubmitNote}>Submit</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export { NoteCard };
