import React, { useState } from "react";

const Tooltip = ({ children, message, classes }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative">
      <div
        className="flex cursor-pointer items-center justify-center"
        onClick={() => setShowTooltip(!showTooltip)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
      </div>
      {showTooltip && (
        <div
          className={
            "absolute w-[max-content] bottom-[-5] right-0 mt-1 bg-black text-slate-50 rounded-md py-1 px-2 pointer-events-none transition-all duration-300 z-40 " +
            classes
          }
        >
          {message}
        </div>
      )}
    </div>
  );
};

export { Tooltip };
