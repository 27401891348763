const statuses = {
  INITIALIZED: "Pending",
  QUEUED: "Pending",
  "IN-PROGRESS": "Processing",
  COMPLETED: "Done",
  FAILED: "Error",
  AWAITING_REVIEW: "Awaiting Review",
};

export { statuses };
