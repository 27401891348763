import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentEditingTerm: {
    definition: "",
    matched_word: "",
    term: "",
    output_id: "",
    term_id: "",
    _id: "",
  },
};

const editTermSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setEditingTerm: (state, action) => {
      state.currentEditingTerm = { ...action?.payload };
    },
    resetEditingTerm: (state, action) => {
      state.resourceList = {
        definition: "",
        matched_word: "",
        term: "",
        output_id: "",
        term_id: "",
        _id: "",
      };
    },
  },
});

export const { setEditingTerm, resetEditingTerm } = editTermSlice.actions;

export default editTermSlice.reducer;
