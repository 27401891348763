import { createSlice, nanoid, original } from "@reduxjs/toolkit";

const initialState = {
  showHighlightModal: false,
  originalSelectedText: "",
  selectedText: "",
  annotation: "",
  annotationList: [],
};

const highlightSlice = createSlice({
  name: "highlight",
  initialState,
  reducers: {
    setHighlightSelectedText: (state, action) => {
      const { text,originalText } = action.payload;
      state.selectedText = text;
      state.originalSelectedText = originalText;
    },
    updateHighlightAnnotation: (state, action) => {
      const { id, newAnnotationText } = action.payload;
      // If the id is falsy, we are editing the new annotation
      if (!id) {
        state.annotation = newAnnotationText;
      } else {
        state.annotationList = state.annotationList.map((ele) => {
          if (ele.id === id) {
            return {
              ...ele,
              annotation: newAnnotationText,
            };
          }
          return ele;
        });
      }
    },
    addHighlightAnnotation: (state, action) => {
      const { newAnnotation } = action.payload;
      const toAdd = {
        id: nanoid(),
        ...newAnnotation,
      };
      state.annotationList = [...state.annotationList, toAdd];
      state.selectedText = "";
      state.annotation = "";
    },
    deleteHighlightAnnotation: (state, action) => {
      const { id } = action.payload;
      state.annotationList = state.annotationList.filter(
        (ele) => ele.id !== id
      );
    },
    toggleHighlightInputModal: (state, action) => {
      state.showHighlightModal = !state.showHighlightModal;
    },
    resetHighlightSelection: (state, action) => {
      state.selectedText = "";
      state.annotation = "";
    },
  },
});

export const {
  setHighlightSelectedText,
  updateHighlightAnnotation,
  addHighlightAnnotation,
  deleteHighlightAnnotation,
  toggleHighlightInputModal,
  resetHighlightSelection,
} = highlightSlice.actions;

export default highlightSlice.reducer;
