import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  current: {
    selectedText: "",
  },
  sectionsList: [
    {
      id: "123",
      selectedText: "Selected text goes here",
      note: "this is a test note",
      editing: false,
    },
  ],
  editingBlockId: "",
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    addNote: (state, action) => {
      state.sectionsList?.push(action?.payload);
    },
    resetsectionsList: (state, action) => {
      state.sectionsList = action?.payload?.newsectionsList;
    },
    removeAll: (state) => {
      state.sectionsList = [];
    },
    rearrangeNotes: (state, action) => {
      state.sectionsList = action?.payload?.newOrder;
    },
    updateCurrentNote: (state, action) => {
      state.current = { ...state?.current, ...action?.payload };
    },
    resetCurrentNote: (state) => {
      state.current = {
        selectedText: "",
      };
    },
    toggleEditNode: (state, action) => {
      const { id } = action.payload;
      state.sectionsList = state.sectionsList.map((ele) => {
        if (ele.id === id) {
          return {
            ...ele,
            editing: !ele?.editing,
          };
        }
        return ele;
      });
    },
    updateNote: (state, action) => {
      const { id, ...rest } = action.payload;
      state.sectionsList = state.sectionsList.map((ele) => {
        if (ele.id === id) {
          return {
            ...ele,
            ...rest,
            editing: false,
          };
        }
        return ele;
      });
    },
    deleteNote: (state, action) => {
      const { id } = action.payload;
      state.sectionsList = state.sectionsList.filter((ele) => {
        return ele.id !== id;
      });
    },
    addIntro: (state, action) => {
      const { id } = action.payload;
      const newSections = state.sectionsList.map((section) => {
        if (section.id === id) {
          if (section.blocks && section.blocks.length > 0) {
            return {
              ...section,
              blocks: [
                {
                  id: nanoid(),
                  type: "Intro",
                  content: "",
                  placeHolder: "Write Intro script here",
                  context: {
                    selectedText: "",
                    pageNumber: -1,
                    boundingBox: [],
                  },
                },
                ...section.blocks,
              ],
            };
          } else {
            return {
              ...section,
              blocks: [
                {
                  id: nanoid(),
                  type: "Intro",
                  content: "",
                  placeHolder: "Write Intro script here",
                  context: {
                    selectedText: "",
                    pageNumber: -1,
                    boundingBox: [],
                  },
                },
              ],
            };
          }
        }
        return section;
      });
      return { ...state, sectionsList: newSections };
    },
    addOutro: (state, action) => {
      const { id } = action.payload;
      const newSections = state.sectionsList.map((section) => {
        if (section.id === id) {
          if (section.blocks && section.blocks.length > 0) {
            return {
              ...section,
              blocks: [
                ...section.blocks,
                {
                  id: nanoid(),
                  type: "Outro",
                  content: "",
                  placeHolder: "Write Outro script here",
                  context: {
                    selectedText: "",
                    pageNumber: -1,
                    boundingBox: [],
                  },
                },
              ],
            };
          } else {
            return {
              ...section,
              blocks: [
                {
                  id: nanoid(),
                  type: "Outro",
                  content: "",
                  placeHolder: "Write Outro script here",
                  context: {
                    selectedText: "",
                    pageNumber: -1,
                    boundingBox: [],
                  },
                },
              ],
            };
          }
        }
        return section;
      });
      return { ...state, sectionsList: newSections };
    },

    createNewSection: (state, action) => {
      const { context, title, type, toolMetadata, content } = action?.payload;
      state.sectionsList?.push({
        id: nanoid(),
        context: {
          pageNumber: -1,
          boundingBox: [],
          selectedText: "",
        },
        title,
        blocks: [
          // {
          //   id: nanoid(),
          //   type: "Intro",
          //   content: "",
          //   placeHolder: "Write intro script here",
          //   context: { selectedText: "", pageNumber: -1, boundingBox: [] },
          // },
          {
            id: nanoid(),
            type,
            toolMetadata,
            context,
            content: content ? content : "",
          },
          // {
          //   id: nanoid(),
          //   type: "Outro",
          //   content: "",
          //   placeHolder: "Write outro script here",
          //   context: { selectedText: "", pageNumber: -1, boundingBox: [] },
          // },
        ],
      });
    },
    createEmptySection: (state, action) => {
      state.sectionsList?.push({
        id: nanoid(),
        context: {
          pageNumber: -1,
          boundingBox: [],
          selectedText: "",
        },
        title: "untitled",
        blocks: [
          // {
          //   id: nanoid(),
          //   type: "Intro",
          //   content: "",
          //   placeHolder: "Write intro script here",
          //   context: { selectedText: "", pageNumber: -1, boundingBox: [] },
          // },
          // {
          //   id: nanoid(),
          //   type: "Outro",
          //   content: "",
          //   placeHolder: "Write outro script here",
          //   context: { selectedText: "", pageNumber: -1, boundingBox: [] },
          // },
        ],
      });
    },
    addBlockToSection: (state, action) => {
      const { sectionId, blockData } = action?.payload;
      const newsectionsList = state.sectionsList.map((note) => {
        if (note?.id === sectionId) {
          if (note.blocks[note.blocks.length - 1]?.type === "Outro") {
            note?.blocks?.splice(note.blocks.length - 1, 0, {
              ...blockData,
              editing: true,
            });
          } else {
            note?.blocks?.push({ ...blockData, editing: true });
          }
        }
        return note;
      });
      state.sectionsList = newsectionsList;
    },
    // },
    updateBlockNote: (state, action) => {
      const { sectionId, blockId, updatedNote } = action?.payload;
      const newsectionsList = state.sectionsList.map((note) => {
        if (note.id === sectionId) {
          return {
            ...note,
            blocks: note.blocks.map((block) => {
              if (block.id === blockId) {
                return {
                  ...block,
                  content: updatedNote,
                };
              }
              return block;
            }),
          };
        }
        return note;
      });
      state.sectionsList = newsectionsList;
    },
    updateTitle: (state, action) => {
      const { sectionId, updatedTitle } = action?.payload;
      const newsectionsList = state.sectionsList.map((note) => {
        if (note.id === sectionId) {
          return {
            ...note,
            title: updatedTitle,
          };
        }
        return note;
      });
      state.sectionsList = newsectionsList;
    },
    deleteBlock: (state, action) => {
      const { sectionId, blockId } = action?.payload;
      const newsectionsList = state.sectionsList.map((note) => {
        if (note.id === sectionId) {
          return {
            ...note,
            blocks: note.blocks.filter((block) => {
              return block.id !== blockId;
            }),
          };
        }
        return note;
      });
      state.sectionsList = newsectionsList;
    },
    deleteSection: (state, action) => {
      const { sectionId } = action?.payload;
      const newsectionsList = state.sectionsList.filter((note) => {
        return note.id !== sectionId;
      });
      state.sectionsList = newsectionsList;
    },
    setEditingBlock: (state, action) => {
      const { blockId } = action?.payload;
      state.editingBlockId = blockId;
    },
    resetEditingBlock: (state, action) => {
      const { blockId } = action?.payload;
      if (state.editingBlockId === blockId) {
        state.editingBlockId = "";
      }
    },
    rearrangeBlocks: (state, action) => {
      const { sectionId, newOrder } = action?.payload;
      const newsectionsList = state.sectionsList.map((note) => {
        if (note.id === sectionId) {
          return newOrder;
        }
        return note;
      });
      state.sectionsList = newsectionsList;
    },
  },
});

export const {
  addNote,
  removeAll,
  updateCurrentNote,
  resetCurrentNote,
  toggleEditNode,
  updateNote,
  deleteNote,
  addIntro,
  addOutro,
  rearrangeNotes,
  resetsectionsList,
  createNewSection,
  createEmptySection,
  addBlockToSection,
  updateBlockNote,
  updateTitle,
  deleteBlock,
  deleteSection,
  setEditingBlock,
  resetEditingBlock,
  rearrangeBlocks,
} = notesSlice.actions;

export default notesSlice.reducer;
