import React from "react";

const ResourceList = ({ files, toggleFileState }) => {
  return (
    <div className="flex flex-col gap-1">
      {files?.map((file) => {
        const { id, file_name: name, isChosen } = file;
        return (
          <div
            className="flex gap-3 cursor-pointer"
            key={id}
            onClick={() => toggleFileState(id)}
          >
            <input
              value={name}
              type="checkbox"
              className="accent-blue-700 cursor-pointer"
              checked={isChosen}
            />
            <span className="text-lg">{name}</span>
          </div>
        );
      })}
    </div>
  );
};

export { ResourceList };
