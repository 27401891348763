import axios from "axios";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button } from "../Button/Button";
import toast from "react-hot-toast";
import { handlePlugin } from "../../utils";
import { useDetectClick } from "../../hooks";
import { ResourceList } from "../ResourceList/ResourceList";
import { Chat } from "../Chat/Chat";
import { Tooltip } from "../Tooltip/Tooltip";

const PluginsDropdown = ({
  id,
  show,
  position,
  selectedText,
  userInput,
  domain,
  closeContextMenu,
  updateValue,
  pluginsDropdownRef,
  resourcesList,
  setResourcesList,
}) => {
  // use tailwind classes for styling
  const [components, setComponents] = useState(null);
  const [pluginsList, setPluginsList] = useState([]);
  const [currentPlugin, setCurrentPlugin] = useState("");
  const [componentValues, setComponentValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/get_plugins_list`
        );
        setPluginsList(response.data);
      } catch (error) {
        toast.error("Something went wrong");
      }
    };
    fetchData();
  }, []);

  const toggleFileChosenState = (id) => {
    setResourcesList((prev) =>
      prev.map((ele) => {
        if (ele?.id === id) {
          return { ...ele, isChosen: !ele.isChosen };
        }
        return ele;
      })
    );
  };

  const handleOutsideClick = (e) => {
    setComponents(false);
    closeContextMenu();
  };

  //for dropdown list
  let calculatedHeight = window.innerHeight - pluginsList.length * 300 + 500;
  if (components) {
    //for components list
    calculatedHeight = window.innerHeight - pluginsList.length * 350 + 500;
  }
  useDetectClick(pluginsDropdownRef, handleOutsideClick, !show);
  const style = {
    display: show ? "block" : "none",
    position: "absolute",
    top: Math.min(position.y, calculatedHeight),
    left: Math.min(position.x, window.innerWidth - 1800),
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    borderRadius: "4px",
    zIndex: 1000,
  };

  const getPlugin = async (pluginType) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/get_plugin`,
        { type: pluginType, selected_text: selectedText }
      );
      setCurrentPlugin(pluginType);
      setComponents(response.data);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handlePluginValueChange = (id, newValue) => {
    setComponents((prev) =>
      prev.map((component) => {
        if (component.id === id) {
          return {
            ...component,
            value: newValue,
          };
        }
        return component;
      })
    );
  };

  const handleValidation = (type, value) => {
    // Basic validation logic, you can customize it based on your needs
    switch (type) {
      case "textArea":
        return value.trim() === "" ? "Description is required" : "";
      case "singleline_input":
        return value.trim() === "" ? "Test field is required" : "";
      default:
        return "";
    }
  };

  const validateInputs = () => {
    let isFilled = true;

    components.forEach((ele) => {
      const { type, value, required } = ele;
      if (type === "singleline_input" || type === "multiline_input") {
        if (required && value.trim() === "") {
          isFilled = false;
        }
      }
    });

    setComponents((prev) => {
      return prev.map((ele) => {
        const { type, value, required } = ele;
        if (type === "singleline_input" || type === "multiline_input") {
          if (required && value.trim() === "") {
            return {
              ...ele,
              error: "Field is required",
            };
          } else {
            return {
              ...ele,
              error: "",
            };
          }
        }
        return ele;
      });
    });
    return isFilled;
  };

  const getFinalResult = (input, context, output) => {
    if (!input.includes(context)) {
      // If the context is not found in the input, return the original input
      return input;
    }

    const result = input.replace(context, output);
    return result;
  };

  const handlePluginSubmit = async () => {
    const isFilled = validateInputs();
    if (!isFilled) {
      toast.error("Fill all the required fields");
      return;
    }
    //close the modal
    handleOutsideClick();
    let payload = {
      pluginType: currentPlugin,
      domain: domain,
      userInput: userInput,
      context: selectedText,
      instruction: components,
    };
    if (currentPlugin === "RAG Instruct") {
      payload.filesList = resourcesList;
    }
    let output = await handlePlugin(payload);
    const newValue = getFinalResult(userInput, selectedText, output);
    updateValue(newValue);
    // toast.error("Work in progress");
  };

  const getComponent = (component) => {
    const { id, type, placeholder, error, value, content, label, required } =
      component;
    switch (type) {
      case "text":
        return <div key={id}>{content}</div>;
      case "context":
        return (
          <div className="flex flex-col gap-2">
            <p className="text-lg font-bold">Context :</p>
            <div className="">{content}</div>
          </div>
        );
      case "title":
        return (
          <h1 className="text-center text-2xl font-bold" key={id}>
            {content}
          </h1>
        );
      case "multiline_input":
        return (
          <div className="flex flex-col gap-2">
            {label && (
              <p className="text-lg font-bold">
                {label}
                {required && <span className="text-red-500">*</span>}
              </p>
            )}
            <textarea
              placeholder={placeholder}
              value={value}
              key={id}
              onChange={(e) => {
                handlePluginValueChange(id, e.target.value);
              }}
              className="w-[100%] min-w-[400px] h-[100px] outline-none border-grey-400 border-2 p-3"
            />
            <div style={{ color: "red" }}>{error}</div>
          </div>
        );
      case "singleline_input":
        return (
          <div className="flex flex-col gap-2">
            {label && (
              <p className="text-lg font-bold">
                {label}
                {required && <span className="text-red-500">*</span>}
              </p>
            )}
            <input
              type="text"
              placeholder={placeholder}
              value={value}
              key={id}
              onChange={(e) => {
                handlePluginValueChange(id, e.target.value);
              }}
              className="outline-none border-grey-400 border-2 p-3 rounded-sm"
            />
            <div style={{ color: "red" }}>{error}</div>
          </div>
        );
      case "RAG-resource-list":
        return (
          <div className="flex flex-col gap-2 max-h-[200px] overflow-auto">
            <h2 className="text-lg font-bold">Files to Refer</h2>
            <ResourceList
              files={resourcesList}
              toggleFileState={toggleFileChosenState}
              resourcesList={resourcesList}
              setResourcesList={setResourcesList}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    // <div
    //   className={
    //     "fixed z-10 inset-0 h-screen w-screen bg-[rgba(0,0,0,0.5)] flex items-center justify-center " +
    //     (!show ? "hidden" : "")
    //   }
    // >
    <div
      style={style}
      className="max-w-[600px] p-3 py-4"
      ref={pluginsDropdownRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {components && (
        <div className="flex flex-col gap-6">
          {/* <ReactFromJSON entry={entry} mapping={mapping} /> */}
          {components.map((component) => getComponent(component))}
          <Button onClick={() => handlePluginSubmit()}>Submit</Button>
        </div>
      )}
      {pluginsList.length > 0 && !components && (
        <h1 className="text-2xl text-center mb-3 font-bold">Task agents</h1>
      )}
      {pluginsList.length > 0 &&
        !components &&
        pluginsList.map((ele) => (
          <div className="flex flex-col gap-3 p-2">
            <Tooltip message={ele?.description}>
              <Button
                classes={"w-[100%]"}
                onClick={(e) => {
                  e.stopPropagation();
                  // e.stopPropagation();
                  getPlugin(ele?.id);
                }}
              >
                {ele?.value}
              </Button>
            </Tooltip>
          </div>
        ))}
    </div>
    // </div>
  );
};

export { PluginsDropdown };
