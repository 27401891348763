import React, { useEffect, useState } from "react";
import { dateToString, getOutputs } from "../../utils";
import { Link } from "react-router-dom";
import { MdContentCopy } from "react-icons/md";
import toast from "react-hot-toast";
import { statuses } from "../../consts";

const OutputList = ({ resourceId }) => {
  const [outputs, setoutputs] = useState([]);
  useEffect(() => {
    (async () => {
      const newOutputs = await getOutputs(resourceId);
      setoutputs(newOutputs);
    })();
  }, []);
  const copyLink = (eleId) => {
    try {
      navigator.clipboard.writeText(
        `${process.env.REACT_APP_FRONTEND_BASE_URL}/interact/${eleId}`
      );
      toast.success("Link copied");
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  const getCTAEle = (id, type, status) => {
    let path = "";

    switch (type) {
      case "interactive_reading":
        path = "/interact";
        break;
      case "video":
        path = "/videos";
        break;
      case "deck":
        path = "/decks";
        break;
      default:
        break;
    }

    if (status === "COMPLETED") {
      return (
        <>
          <Link
            to={`${path}/${id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            checkout
          </Link>
          <MdContentCopy
            className="cursor-pointer"
            onClick={() => copyLink(id)}
          />
        </>
      );
    } else if (status === "waiting for approval") {
      return (
        <>
          <Link
            to={`/shots/${id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Processing...
          </Link>
        </>
      );
    } else if (status === "ERROR") {
      return (
        <div
          to={`/shots/${id}`}
          className="text-indigo-600 hover:text-indigo-900"
          onClick={() => toast.success("WIP")}
        >
          retry
        </div>
      );
    } else {
      return <p className="cursor-not-allowed	text-slate-500">Checkout</p>;
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto min-w-full divide-y divide-gray-200">
        <thead>
          <tr className="bg-gray-50 text-left">
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Title
            </th>
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Type
            </th>
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Date and Time
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Status
            </th>
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Checkout
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {outputs.map((ele) => {
            return (
              <tr key={ele._id} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {ele.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {ele.type}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {/* {ele.domain} */}
                  {dateToString(ele?.created_at)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {/* {ele.domain} */}
                  {statuses[ele?.status]}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex gap-3 items-center">
                  {getCTAEle(ele?._id, ele?.type, ele?.status)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export { OutputList };
