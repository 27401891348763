import { useState } from "react";
import { RxTriangleRight, RxTriangleDown } from "react-icons/rx";

const ShotPreviewCard = ({
  title,
  videoUrl,
  id,
  displayContent,
  displayType,
  voiceoverText,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full max-w-[80%] mx-auto flex flex-col bg-blue-50 rounded-md">
      <div className=" rounded-md w-[95%] m-auto mt-3 mb-4">
        <p className="text-xl font-semibold ">{title}</p>
      </div>
      <div className=" bg-blue-200 rounded-md mb-5  w-[95%] m-auto">
        <div
          onClick={toggleCollapse}
          className="flex items-center gap-1 bg-blue-400 px-4 py-2 text-white rounded-md text-left cursor-pointer"
        >
          {isOpen ? <RxTriangleDown /> : <RxTriangleRight />}
          <p>Script</p>
        </div>
        {isOpen && (
          <div className="flex gap-5 m-4 py-3">
            <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow">
              {displayContent}
            </div>
            <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow">
              {voiceoverText}
            </div>
          </div>
        )}
      </div>
      <div className="mb-4 mx-auto">
        <video src={videoUrl} height={400} width={1000} controls />
      </div>
    </div>
  );
};

export { ShotPreviewCard };
