import React from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useDispatch } from "react-redux";
import { resetSelectedContext } from "../../features";

const ContextBar = ({ text, isInRecordMode }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(resetSelectedContext());
  };
  return (
    <div className="bg-[#afbace] p-2 rounded-md">
      <div className="flex justify-between mb-1 border-b-2 border-black">
        <p className={"font-bold " + (isInRecordMode ? " text-xl " : "")}>
          Ask About
        </p>
        <IoMdCloseCircleOutline
          className="cursor-pointer"
          onClick={handleClose}
        />
      </div>
      <p
        className={
          "max-h-[250px] overflow-y-scroll no-scrollbar " +
          (isInRecordMode ? "text-lg" : "")
        }
      >
        {text}
      </p>
    </div>
  );
};

export { ContextBar };
