import React, { useRef, useState } from "react";
import { PluginsDropdown } from "../PluginsDropdown/PluginsDropdown";
import { useSelector } from "react-redux";

const NoteTextBox = ({ value, setValue, isDisabled, placeHolder, classes }) => {
  const [contextMenu, setContextMenu] = useState({
    show: false,
    position: { x: 0, y: 0 },
    selectedText: "",
  });
  const pluginsDropdownRef = useRef(null);
  const state = useSelector((state) => state);
  const handleTextSelection = (e) => {
    e.preventDefault();
  };
  const handleContextMenu = (e) => {
    e.preventDefault();
    const selectedText = window.getSelection().toString();
    if (selectedText.trim()) {
      setContextMenu({
        show: true,
        position: { x: e.pageX, y: e.pageY },
        selectedText: selectedText,
      });
    }
  };
  const replaceInstructionWithResult = (newValue) => {
    setValue(newValue);
  };
  const closeContextMenu = () => {
    setContextMenu((prev) => {
      return { ...prev, show: false };
    });
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <>
      <textarea
        className={
          (classes ? classes : "") +
          " border-2 border-grey-400 outline-none rounded-md p-2 w-full min-h-[50px] h-fit bg-gray-50 "
        }
        onChange={handleChange}
        value={value}
        disabled={isDisabled}
        placeholder={placeHolder}
        // onMouseUp={handleTextSelection}
        onContextMenu={handleContextMenu}
      />
      <PluginsDropdown
        // id={ele?.id}
        show={contextMenu.show}
        position={contextMenu.position}
        selectedText={contextMenu.selectedText}
        userInput={value}
        // domain={domain}
        pluginsDropdownRef={pluginsDropdownRef}
        closeContextMenu={closeContextMenu}
        updateValue={replaceInstructionWithResult}
        // resourcesList={filesList}
        // setResourcesList={setFilesList}
      />
    </>
  );
};

export { NoteTextBox };
